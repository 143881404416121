import { Query, toQuery } from "../helpers/general";

export const apiConfig = {
  offers: {
    set_order: () => "specials/orders",
    all: (query: Query) => `specials/all${toQuery({ ...query })}`
  },
  events: {
    set_order: () => "events/orders",
    all: (query: Query) => `events/ours${toQuery({ ...query })}`
  },
  users: {
    list: (query: string) => `users/filter?${query}`,
    drivers: {
      complete_profile: (driverId: string) =>
        `users/${driverId}/driver/complete-profile`,
      upload_docs: (driverId: string) =>
        `users/driver-profile/${driverId}/upload-doc`,
      set_expiration_date: (driverId: string) =>
        `users/driver-profile/${driverId}/set-expiration-date`
    },
    rides: (id: string, query: Query) =>
      `users/${id}/on-going-rides${toQuery({ ...query })}`
  },
  places: {
    get: (placeId: string) => `places/${placeId}`
  },
  rides: {
    all: (query: Query) => `rides${toQuery({ ...query })}`,
    chats: (rideId: String) => `rides/${rideId}/conversations`,
    get: (rideId: String) => `rides/${rideId}`,
    valid_ride: (rideId: String) => `rides/${rideId}/mark-valid`,
    invalid_ride: (rideId: String) => `rides/${rideId}/mark-invalid`
  },
  transactions: {
    all: (query: Query) => `transactions${toQuery({ ...query })}`,
    topup: (userId: string) => `transactions/balance/${userId}/topup`,
    ride_bonus: (userId: string) => `transactions/${userId}/rides-bonus`,
    ride_discount: (userId: string) => `transactions/${userId}/rides-discount`
  }
};
