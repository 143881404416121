import {
  CFormInput,
  CModal,
  CModalContent,
  CNav,
  CNavItem,
  CNavLink,
  CSpinner,
  CTabContent,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTabPane
} from "@coreui/react";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { WithContext as ReactTags } from "react-tag-input";
import Icon, { LocationIcon } from "../../components/icons";
import DeleteIcon from "../../components/icons/DeleteIcon";
import EditIcon from "../../components/icons/EditIcon";
import { DAYS_OF_THE_WEEK } from "../../config/constants";
import { colors } from "../../config/theme";
import { getCurrentDayOpeningHours } from "../../helpers/general";
import { useCookie } from "../../hooks/useCookie";
import { useQueryParam } from "../../hooks/useQueryParam";
import { useToast } from "../../hooks/useToast";
import { general } from "../../locales/general";
import apiService from "../../service/apiService";
import { OFFER_TYPES } from "../Offers/NewOffer";
import Services from "./Services";

export default function Place() {
  const params = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [reviewLimit, setReviewLimit] = useState(5);
  const { token } = useCookie("vToken");
  const { add } = useQueryParam();
  const [searchParams] = useSearchParams();

  const { data } = useQuery<Place>(["getPlace"], () => {
    return apiService.MakeGetRequest(`places/${params?.placeId}`);
  });
  const { data: offers } = useQuery<SpecialOffers[]>(["getPlaceOffers"], () => {
    return apiService.MakeGetRequest(`places/${params?.placeId}/specials`);
  });

  const { data: packs } = useQuery<Pack[]>(["getPlacePacks"], () => {
    return apiService.MakeGetRequest(`places/${params?.placeId}/packs`);
  });
  console.log(packs);

  const { data: services } = useQuery<Service[]>(["getPlaceServices"], () => {
    return apiService.MakeGetRequest(`places/${params?.placeId}/services`);
  });
  const { data: reviews } = useQuery<{ items: Review[] }>(
    ["getPlaceReviews"],
    () => {
      return apiService.MakeGetRequest(`places/${params?.placeId}/ratings`);
    }
  );

  // const approved = offerStatus === "approved";
  // const rejected = offerStatus === "rejected";
  // const pending = offerStatus?.toLowerCase() === "pending";

  const currentDayOpeningHour = getCurrentDayOpeningHours(data?.openingHours);

  const getOfferUpdateLink = (offer: { id: string; type: string }) => {
    if (offer.type === OFFER_TYPES.SPECIAL) {
      return `/offers/update/special-offer/${offer.id}`;
    } else if (offer.type === OFFER_TYPES.EVENT) {
      return `/offers/update/event-offer/${offer.id}`;
    } else if (offer.type === OFFER_TYPES.TOUR) {
      return `/offers/update/tour-offer/${offer.id}`;
    }
    return "";
  };

  const deletePack = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`packs/${id}`, token);
  });

  const deleteOffer = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`specials/${id}`, token);
  });

  const updateOfferStatus = useMutation((url: string) => {
    return apiService.MakePutRequest(`${url}`, {}, token);
  });

  return (
    <main>
      {/* <div className="row gap-3 pb-3">
        <div className="border-0 position-relative col col-lg-4">
          <img src={staticsLogos3} alt="" className="w-100" />

          <div className="card-content">
            <p className="m-0 fs-2 text-white fw-bold mb-0">{11}</p>
            <p className="fs-5">Bookings</p>
          </div>
        </div>
        <div className="mt-4 col col-lg-3 ">
          <button
            onClick={() => setShowModal(true)}
            className="btn btn-danger rounded-sm p-2 shadow-primary w-100 text-white"
          >
            Add Opening Hours
          </button>
          <button
            onClick={() => setShowServiceModal(true)}
            className="btn btn-primary rounded-sm p-2 shadow-secondary w-100 mt-2 text-white"
          >
            Add services
          </button>
         
        </div>
      </div> */}
      <div className="px-4 rounded-lg">
        <div className=" mb-5">
          <div className="d-flex align-items-center gap-5">
            <h5 className="fw-semibold fs-4 text-capitalize">{data?.name}</h5>
            {data?.rating && (
              <p className="text-gradient-primary fw-bold d-flex align-items-center text-sm gap-x-2">
                {" "}
                <Icon name="star" width={20} height={20} />{" "}
                {Math.floor(data?.rating)}/5
              </p>
            )}
          </div>
          <div className="d-flex align-items-center gap-2">
            <LocationIcon width={"22"} height={"22"} />
            <p className="mb-0">{data?.address}</p>
          </div>
          {data?.averagePrice && data?.averagePrice > 0 && (
            <div className="d-flex align-items-center gap-2 mt-1 mb-4">
              <Icon name="creditCard" width={20} /> Prix moyen :
              <p className="text-primary mb-0">{data?.averagePrice} DH </p>
            </div>
          )}
          <CNav variant="pills" className="w-full mt-3">
            <CNavItem>
              <CNavLink
                href="#"
                active={activeTab === 0}
                onClick={() => setActiveTab(0)}
              >
                A Propos
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                href="#"
                active={activeTab === 1}
                onClick={() => setActiveTab(1)}
              >
                Service
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                href="#"
                active={activeTab === 3}
                onClick={() => setActiveTab(3)}
              >
                Offres
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                href="#"
                active={activeTab === 4}
                onClick={() => setActiveTab(4)}
              >
                Packs
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                href="#"
                active={activeTab === 5}
                onClick={() => setActiveTab(5)}
              >
                Avis
              </CNavLink>
            </CNavItem>
          </CNav>
          <CTabContent>
            <CTabPane visible={activeTab === 0}>
              <div>
                <h5 className="fw-semibold my-3 fs-6">
                  Emplacement et coordonnées
                </h5>
                <div className="my-2">
                  <div className="d-flex align-items-center gap-2 mb-1">
                    <div>
                      <LocationIcon width="20" height="20" />
                    </div>
                    <p className="fw-light text-sm w-[55%] mb-0">
                      {data?.address}
                    </p>
                  </div>
                  {/* {currentDayOpeningHour && (
                    <div className="d-flex align-items-center gap-2 mb-1">
                      <div>
                        <Icon name="clock" width={20} height={20} />
                      </div>
                      <p className="fw-light text-sm w-[55%] mb-0">{`${
                        currentDayOpeningHour?.from
                      }H - ${currentDayOpeningHour?.to}H`}</p>
                    </div>
                  )} */}
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <div>
                      <Icon name="phone" width={20} height={20} />
                    </div>
                    <p className="fw-light text-sm w-[55%] mb-0">
                      {data?.phone}
                    </p>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-1">
                    <div>
                      <Icon name="envelop" width={20} height={20} />
                    </div>
                    <p className="fw-light text-sm w-[55%] mb-2">
                      {data?.email}
                    </p>
                  </div>
                  {data?.website && (
                    <div className="d-flex align-items-center gap-2 mb-2">
                      <div className="">
                        <Icon name="web" width={20} height={20} />
                      </div>
                      <a
                        href={data?.website}
                        target="__blank"
                        className="fw-light text-sm w-[55%] mb-0 text-body"
                      >
                        {data?.website
                          .replace(/^https?:\/\//i, "")
                          .replace(/\/$/, "")}
                      </a>
                    </div>
                  )}
                  {data?.instagramURL && (
                    <div className="d-flex align-items-center gap-2 mb-2">
                      <div>
                        <Icon name="instagram" width={20} height={20} />
                      </div>

                      <a
                        href={data?.instagramURL}
                        target="__blank"
                        className="fw-light text-sm w-[55%] text-body"
                      >
                        {data?.instagramURL.replace(
                          /^https?:\/\/(www\.)?instagram\.com\/(.+?)\/?$/,
                          "$2"
                        )}{" "}
                      </a>
                    </div>
                  )}
                  {data?.facebookURL && (
                    <div className="d-flex align-items-center gap-2 mb-1">
                      <div>
                        <Icon name="facebook_2" width={20} height={20} />
                      </div>
                      {data?.facebookURL.includes("profile.php?id") ? (
                        <a
                          href={data?.facebookURL}
                          target="__blank"
                          className="fw-light text-sm w-[55%] text-capitalize text-body"
                        >
                          {data?.name}
                        </a>
                      ) : (
                        <a
                          href={data?.facebookURL}
                          target="__blank"
                          className="fw-light text-sm w-[55%] text-body"
                        >
                          {data?.facebookURL.replace(
                            /^https:\/\/www\.facebook\.com\//,
                            ""
                          )}
                        </a>
                      )}
                    </div>
                  )}
                </div>
                <div className="my-2">
                  {/* details */}
                  <div className="my-8">
                    <h5 className="fw-semibold my-3 fs-6">Détails</h5>

                    {data?.keywords && (
                      <div className="d-flex align-items-center gap-1 mb-1">
                        <div>
                          <Icon name="cutlery" width={20} height={20} />
                        </div>
                        <p className="fw-light text-sm w-[55%] mb-0">
                          {data?.keywords}
                        </p>
                      </div>
                    )}
                    <div className="d-flex align-items-center gap-1 mb-1">
                      <div>
                        <Icon name="wallet" width={20} height={20} />
                      </div>
                      <p className="fw-light text-sm w-[55%] mb-0">
                        Paiement par{" "}
                        {data?.paymentMethods.map(method => `${method},`)}
                      </p>
                    </div>
                    <div className="d-flex align-items-center gap-1 mb-1">
                      <div>
                        <Icon name="parking" width={20} height={20} />
                      </div>
                      <p className="fw-light text-sm w-[55%] mb-0">
                        Parking: {data?.hasParking ? "Disponible" : "Non"}
                      </p>
                    </div>
                  </div>
                  {/* working hours */}
                  <div>
                    <h5 className="fw-semibold my-3 fs-6">
                      Horaires d&apos;ouverture
                    </h5>
                    {data?.openingHours ? (
                      DAYS_OF_THE_WEEK.map(day => (
                        <div
                          className="d-flex align-items-center justify-content-between p-1 border-bottom"
                          key={day?.fr?.toUpperCase()}
                        >
                          <div className="d-flex align-items-center">
                            <div className="mt-1">
                              <Icon name="clock" />
                            </div>
                            <p className="font-light text-capitalize text-sm md:text-md m-0">
                              {day.fr}
                            </p>
                          </div>
                          {data?.openingHours?.[day.en]?.ranges ? (
                            <div className="d-flex flex-column align-items-end">
                              {data?.openingHours[day.en]?.ranges?.map(
                                (range, index) => (
                                  <p
                                    key={index}
                                    className="!font-roboto text-sm md:text-md m-0"
                                  >
                                    {`${range.from}H - ${range?.to!}H`}
                                  </p>
                                )
                              )}
                            </div>
                          ) : data?.openingHours?.[day.en]?.openAllDay ? (
                            <p className="font-light text-sm md:text-md m-0">
                              Ouvert toute la journée
                            </p>
                          ) : (
                            <p className="font-light text-sm md:text-md m-0">
                              Fermé
                            </p>
                          )}
                        </div>
                      ))
                    ) : (
                      <p className="text-light">
                        Pas de données à afficher pour le moment
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </CTabPane>
            <CTabPane visible={activeTab === 1}>
              <Services menuURL={data?.menuURL} services={services!} />
            </CTabPane>
            <CTabPane visible={activeTab === 5}>
              <div>
                {reviews?.items.map(review => (
                  <div
                    className="my-4 border-bottom pb-4 px-4"
                    key={review?.id}
                  >
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <div className="d-flex gap-3 mb-2">
                        {review?.user?.profilePicture ? (
                          <img
                            src={review?.user?.profilePicture}
                            alt="profile"
                            width={36}
                            height={36}
                            className="rounded-full !w-[40px] !h-[40px]"
                            style={{ objectFit: "cover" }}
                          />
                        ) : (
                          <div className="bg-gray-200 w-9 h-9 rounded-full" />
                        )}
                        <div>
                          <p className="font-light capitalize mb-0">
                            {review?.user?.fullName}
                          </p>
                          <p className="text-[#5A606C] font-light text-sm">
                            {new Date(review?.createdAt).toLocaleDateString()}
                            {/* //. 10 avis */}
                          </p>
                        </div>
                      </div>
                      <p className="text-gradient-primary flex items-center gap-x-2">
                        {" "}
                        <Icon name="star" width={25} height={25} />{" "}
                        {review?.rating}/5
                      </p>
                    </div>
                    <p className="w-[90%] font-light">{review?.comment}</p>
                  </div>
                ))}
              </div>
            </CTabPane>
            <CTabPane visible={activeTab === 3} className="w-full">
              <div className="flex items-center justify-between my-3 w-[20rem]">
                {/* Heading */}
                <h5 className="fw-semibold">Special Offers</h5>

                {/* Search Form */}
                {/* <div className="search-form flex items-center">
                  <div className="input-field flex items-center bg-gray-100 rounded-sm px-2">
                    <MagnifyingGlassIcon color="gray" width={20} />
                    <input
                      type="text"
                      className="w-50 border-0 outline-none p-1 text-gray fs-6"
                      placeholder="Rechercher"
                      onChange={e =>
                        add("search", e?.target?.value?.toLowerCase())
                      }
                      defaultValue={searchParams?.get("search") ?? ""}
                    />
                  </div>
                </div> */}
              </div>

              {/* Table or Fallback Message */}
              {offers?.length ? (
                <CTable hover responsive className="rounded-lg">
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col">Titre</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Statut</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Type</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Remise</CTableHeaderCell>
                      <CTableHeaderCell scope="col"> </CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {offers.map(offer => (
                      <CTableRow key={offer.id}>
                        <CTableDataCell>{offer.title}</CTableDataCell>
                        <CTableDataCell>{offer?.status?.label}</CTableDataCell>
                        <CTableDataCell>{offer.type}</CTableDataCell>
                        <CTableDataCell>
                          {offer?.reductionPercent > 0
                            ? `${offer?.reductionPercent}%`
                            : ""}
                        </CTableDataCell>
                        <CTableDataCell>
                          <Link
                            to={getOfferUpdateLink(offer)}
                            key={offer.id}
                            className="ms-4"
                          >
                            <EditIcon
                              width="18"
                              height="18"
                              fill={colors.secondary}
                            />
                          </Link>
                          <button
                            className="outline-none border-0 bg-transparent"
                            onClick={() => setShowModal(true)}
                            disabled={updateOfferStatus.isLoading}
                          >
                            {deleteOffer.isLoading ? (
                              <CSpinner size="sm" />
                            ) : (
                              <DeleteIcon
                                width="20"
                                height="20"
                                fill={colors.primary}
                                className="ms-2"
                              />
                            )}
                          </button>
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              ) : (
                <p className="text-muted">Aucune offre disponible.</p>
              )}
            </CTabPane>

            <CTabPane visible={activeTab === 4} className="w-full ">
              <h5 className="fw-semibold my-3">Special Offers</h5>
              {packs?.length ? (
                <CTable hover responsive className="rounded-lg">
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col">Titre</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Prix</CTableHeaderCell>
                      <CTableHeaderCell scope="col"> </CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {packs.map(pack => (
                      <CTableRow key={pack.id}>
                        <CTableDataCell>{pack.title}</CTableDataCell>
                        <CTableDataCell>{pack.price}</CTableDataCell>
                        <CTableDataCell>
                          <Link
                            to={`/packs/update/${pack.id}`}
                            key={pack.id}
                            className="ms-4"
                          >
                            <EditIcon
                              width="18"
                              height="18"
                              fill={colors.secondary}
                            />
                          </Link>

                          <button
                            className="outline-none border-0 bg-transparent"
                            onClick={() => setShowModal(true)}
                            disabled={deletePack.isLoading}
                          >
                            {deletePack.isLoading ? (
                              <CSpinner size="sm" />
                            ) : (
                              <DeleteIcon
                                width="20"
                                height="20"
                                fill={colors.primary}
                                className="ms-2"
                              />
                            )}
                          </button>
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              ) : (
                <p className="text-muted">Aucune offre disponible.</p>
              )}
            </CTabPane>
            {/* <CTabPane visible={activeTab === 3}>
              <h1>Offers</h1>
              <Swiper
                modules={[Pagination, Autoplay]}
                slidesPerView="auto"
                className="venez-offer-swiper mx-500"
                centeredSlides
                centeredSlidesBounds
                autoplay
                spaceBetween={10}
              >
                {offers?.map(item => (
                  <SwiperSlide key={item?.id}>
                    <div className="w-fit h-[215px]  overflow-hidden my-8 relative p-1 rounded-[8px] ">
                      <img
                        src={item.carouselPicture as string}
                        alt=""
                        width={400}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </CTabPane> */}
          </CTabContent>
        </div>

        <aside className="col-md-4 "></aside>
      </div>
      <OpeningHoursModal
        visible={showModal}
        onClose={() => setShowModal(false)}
      />
      <ServiceModal
        visible={showServiceModal}
        onClose={() => setShowServiceModal(false)}
      />
      {/* <section className="d-flex align-items-center gap-4 my-12 justify-content-center col-5 ">
							{data?.menuURL && (
								<a
									href={data?.menuURL}
									target="_blank"
									className="flex justify-center"
									rel="noopener noreferrer"
								>
									<button className="whitespace-nowrap shadow-blue-primary  min-h-[58px] bg-primary rounded-md p-3 lg:px-6 lg:py-4 2xl:py-4 text-white flex justify-center items-center gap-1 transition-all  hover:scale-[1.01] cta-btn text-sm">
										<svg
											width="25"
											height="25"
											viewBox="0 0 20 33"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M18.5147 5.14659H17.4092V1.07074C17.4092 0.414382 17.0292 0 16.5112 0C16.4076 0 16.304 -3.67655e-09 16.2004 0.0344551L1.2091 4.62855C0.518317 4.83577 0 5.63015 0 6.39014V31.3641C0 32.124 0.621634 32.7804 1.41629 32.7804H18.5837C19.3436 32.7804 20 32.1587 20 31.3641V6.49377C19.9308 5.76826 19.3092 5.14662 18.5146 5.14662L18.5147 5.14659ZM6.4595 26.7354C6.32144 27.0118 5.97592 27.0809 5.69958 26.9082L4.80154 26.321C4.55963 26.1482 4.49072 25.8029 4.66348 25.561L8.32488 20.5178L9.15395 21.9685L6.4595 26.7354ZM14.5424 26.3207L13.6097 26.9079C13.3333 27.0807 12.988 26.9771 12.8497 26.7352L8.46282 19.0668L8.18645 19.2049C7.46095 19.5504 6.66654 19.2393 6.3213 18.5832C5.97578 17.9269 5.45775 16.8561 4.45615 14.8871C3.48897 12.9528 4.83613 11.191 5.11252 11.6057L8.98117 17.6161L14.7152 25.5262C14.8877 25.7684 14.8188 26.1483 14.5425 26.3209L14.5424 26.3207ZM15.7512 13.2984L13.6441 16.6834L13.3678 17.0979C13.0914 17.5123 12.677 17.8234 12.2623 17.8923L11.3643 18.065L10.9151 18.8594L10.1896 17.8578L10.466 17.4778L10.2588 16.6143C10.1552 16.1999 10.2588 15.7163 10.5352 15.2672L10.8115 14.8527L13.0566 11.5366C13.0911 11.5021 13.1602 11.4674 13.1947 11.5021L13.5402 11.744C13.5747 11.7785 13.6094 11.8476 13.5747 11.8821L11.502 15.2327C11.4676 15.3018 11.4676 15.4054 11.5365 15.4746C11.5709 15.509 11.6401 15.5437 11.6745 15.5782C11.7437 15.6126 11.8473 15.6126 11.9165 15.5437C12.3309 14.9221 14.1272 12.2967 14.1272 12.2967C14.1616 12.2623 14.2308 12.2276 14.2652 12.2623L14.6107 12.5042C14.6452 12.5386 14.6799 12.6078 14.6452 12.6422L12.5725 15.9928C12.5381 16.062 12.5381 16.1656 12.607 16.2347C12.6414 16.2692 12.7106 16.3039 12.7451 16.3383C12.8142 16.3728 12.9178 16.3728 12.987 16.3039C13.4014 15.6822 15.1977 13.0569 15.1977 13.0569C15.2321 13.0224 15.3013 12.9877 15.3357 13.0224L15.6812 13.2643C15.7858 13.1947 15.7858 13.2636 15.7511 13.2983L15.7512 13.2984ZM16.3386 5.14642H3.28172L16.3386 1.13948V5.14642Z"
												fill="white"
											/>
										</svg>
										Téléchargez le menu
									</button>
								</a>
							)}
							{data?.deepLink && (
								<a
									target="__blank"
									referrerPolicy="no-referrer"
									href={data.deepLink}
									className="whitespace-nowrap flex justify-center items-center min-h-[58px] text-sm text-white font-semibold bg-gradient-to-r from-orange-primary to-yellow-primary p-3 lg:px-6 lg:py-4 2xl:py-4 rounded-md shadow-primary transition-all hover:scale-[1.01] cta-btn"
								>
									Réservez maintenant
								</a>
							)}
						</section> */}
    </main>
  );
}

interface ModalProps {
  visible: boolean;
  onClose: () => void;
}
type serviceProps = {
  label: string;
  startingPrice: string;
  isSpecial: boolean;
  picture: File | undefined;
  otherInfo: string;
};

function ServiceModal({ visible, onClose }: ModalProps) {
  const { token } = useCookie("vToken");
  const { toastSuccess, toastError } = useToast();

  const createServiceMutation = useMutation((data: serviceProps) => {
    return apiService.MakePostRequest("services", data, token, true);
  });

  const createServiceHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const label = formData.get("label") as string;
    const startingPrice = formData.get("startingPrice") as string;
    const isSpecial = formData.get("isSpecial") as string;
    const picture = formData.get("picture") as File;
    const otherInfo = formData.get("otherInfo") as string;
    const data = {
      label,
      startingPrice,
      isSpecial: Boolean(isSpecial),
      picture,
      otherInfo
    };

    createServiceMutation.mutate(data, {
      onSuccess() {
        toastSuccess(general.fr.message.serviceAdded);
        onClose();
      },
      onError() {
        toastError(general.fr.message.operationFailed);
      }
    });
  };
  return (
    <CModal visible={visible} onClose={onClose} size="lg" alignment="center">
      <CModalContent className="p-3">
        <h5 className="fs-5 my-4">Add Service</h5>
        <form onSubmit={createServiceHandler} className="row ">
          <div className="col col-sm-6 mb-3">
            <label htmlFor="name">Offer name</label>
            <input
              type="text"
              className="custom-input"
              name="label"
              id="name"
            />
          </div>
          <div className="col col-sm-6 mb-3">
            <label htmlFor="price">Starting price</label>
            <input
              type="number"
              className="custom-input"
              name="startingPrice"
              id="price"
            />
          </div>
          <div className="col col-sm-6 mb-3">
            <label htmlFor="special">Special offer</label>
            <select name="isSpecial" id="special">
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
          </div>
          <div className="col col-sm-6 mb-3">
            <label htmlFor="picture">Picture</label>
            <CFormInput
              required
              name="picture"
              id="picture"
              type="file"
              accept="image/png,image/jpeg,image/jpg"
            />
          </div>
          <div className="col col-sm-6 mb-3">
            <label htmlFor="description">Description</label>
            <input
              type="text"
              className="custom-input"
              name="otherInfo"
              id="description"
            />
          </div>
          <button className="btn-danger btn shadow-primary mt-3 d-flex align-items-center justify-content-center text-white">
            {createServiceMutation.isLoading ? (
              <>
                <CSpinner size="sm" /> {general.fr.wait}
              </>
            ) : (
              "Save service"
            )}
          </button>
        </form>
      </CModalContent>
    </CModal>
  );
}

const defaultOpeningHours: { [x: string]: Array<number> } = {
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: []
};

function OpeningHoursModal({ visible, onClose }: ModalProps) {
  const [openingHours, setOpeningHours] = useState(defaultOpeningHours);
  const { token } = useCookie("vToken");
  const { toastSuccess, toastError } = useToast();
  const queryClient = useQueryClient();

  const emptyOpeningHours = useMemo(() => {
    return DAYS_OF_THE_WEEK.every(day => openingHours[day.en]?.length === 0);
  }, [openingHours]);

  const openingHoursMutation = useMutation(
    (data: typeof defaultOpeningHours) => {
      return apiService.MakePutRequest("places/ours/openingHours", data, token);
    }
  );

  const addOpeningHours = (day: string, value: Array<number>) => {
    setOpeningHours(currentHours => ({ ...currentHours, [day]: value }));
  };

  const createOpeningHours = () => {
    openingHoursMutation.mutate(openingHours, {
      onSuccess() {
        toastSuccess(general.fr.message.openingHrAdded);
        queryClient.invalidateQueries("getPlace");
        onClose();
      },
      onError() {
        toastError(general.fr.message.operationFailed);
      }
    });
  };

  return (
    <CModal visible={visible} onClose={onClose} size="lg">
      <CModalContent className=" p-3">
        <h5 className="fs-5 my-4">Add opening Hours</h5>
        <div className="row">
          {DAYS_OF_THE_WEEK.map(day => (
            <div key={day.en} className="mb-3 col-6">
              <label htmlFor="" className="text-capitalize d-block mb-2">
                {day.fr}
              </label>

              <OpeningHoursTag day={day.fr} addOpeningHours={addOpeningHours} />
            </div>
          ))}
          <button
            disabled={emptyOpeningHours || openingHoursMutation.isLoading}
            type="button"
            onClick={createOpeningHours}
            className="mt-4 btn btn-danger shadow-primary w-100 p-2 d-flex align-items-center justify-content-center"
          >
            {openingHoursMutation.isLoading ? (
              <>
                <CSpinner size="sm" /> {general.fr.wait}
              </>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </CModalContent>
    </CModal>
  );
}

const KeyCodes = {
  comma: 188,
  enter: 13,
  hyphen: 109
};

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.hyphen];

type Tag = {
  id: string;
  text: string;
};

type HoursTagProps = {
  day: string;
  addOpeningHours: (day: string, value: Array<number>) => void;
};

function OpeningHoursTag({ day, addOpeningHours }: HoursTagProps) {
  const [tags, setTags] = useState<Array<Tag>>([]);

  useEffect(() => {
    addOpeningHours(
      day,
      tags.map(tag => Number(tag.text))
    );
  }, [day, tags]);

  const handleDelete = (i: number) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag: Tag) => {
    if (Number(tag.text) > 24) return;
    setTags([...tags, tag]);
  };

  const handleDrag = (tag: Tag, currPos: number, newPos: number) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  return (
    <ReactTags
      tags={tags}
      delimiters={delimiters}
      handleDelete={handleDelete}
      handleAddition={handleAddition}
      handleDrag={handleDrag}
      inputFieldPosition="bottom"
      placeholder="Utiliser (, ou -) pour séparer les heures"
    />
  );
}
