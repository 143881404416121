import {
  BookingStatus,
  DriverProfileStatus,
  RideStatus,
  TransactionStatus
} from "./enums";

const success = [
  BookingStatus.CONFIRMED,
  BookingStatus.PASSED,
  DriverProfileStatus.APPROVED,
  RideStatus.ON_GOING
];
const neutral = [
  BookingStatus.WAITING,
  DriverProfileStatus.IN_REVIEW,
  RideStatus.NO_DRIVER_FOUND
];
const danger = [
  BookingStatus.CANCELED,
  BookingStatus.REFUSED,
  DriverProfileStatus.DECLINED,
  DriverProfileStatus.BLOCKED,
  RideStatus.USER_CANCELLED,
  RideStatus.DRIVER_CANCELLED
];
const completed = [
  BookingStatus.CASH_PRE_PAYMENT,
  RideStatus.COMPLETED,
  DriverProfileStatus.IN_REVIEW,
  TransactionStatus.WAITING_FOR_PAYMENT
];

export const getBadgeClassNameByStatus = (status: string) => {
  const itemStatus = status as BookingStatus | DriverProfileStatus | RideStatus;
  if (success.includes(itemStatus)) return "badge-success";
  if (danger.includes(itemStatus)) return "badge-danger";
  if (completed.includes(itemStatus)) return "badge-completed";
  if (neutral.includes(itemStatus)) return "badge-neutral";
  return "badge-neutral";
};
