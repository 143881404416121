import {
  CFormCheck,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import { DELETE_ACTIONS } from "../../../config/constants";
import { colors } from "../../../config/theme";
import { formatDateString } from "../../../helpers/general";
import { Link } from "react-router-dom";
import EditIcon from "../../../components/icons/EditIcon";
import PromptModal from "../../../components/modals/PromptModal";

interface TaxiCouponsTableProps {
  coupons: Coupon[];
  TaxiCouponsTableToBeDeleted: (action: BulkDeleteActions) => void;
  deleteCoupon: (id: string) => void;
  isDeleting: boolean;
}

interface TableBodyProps {
  coupon: Coupon;
  deletedList: React.Dispatch<BulkDeleteActions>;
  onDelete: () => void;
}

function TableBodyRowContent({
  coupon,
  deletedList,
  onDelete
}: TableBodyProps) {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (isChecked) {
      deletedList({ type: DELETE_ACTIONS.ADD, id: coupon.id });
    } else {
      deletedList({ type: DELETE_ACTIONS.REMOVE, id: coupon.id });
    }
  }, [deletedList, isChecked, coupon.id]);

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="px-3 text-sm">
        <div className="d-flex align-items-center gap-3">
          <CFormCheck
            id="flexCheckDefault"
            label=""
            defaultChecked={isChecked}
            onChange={e => setIsChecked(e.target.checked)}
            color={colors.yellow}
          />

          <p className="m-0">{coupon.name}</p>
        </div>
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        <div className="d-flex align-items-center gap-3">
          {formatDateString(coupon.experationDate)}
        </div>
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {coupon.isActive ? "Oui" : "Non"}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {coupon.isPublic ? "Oui" : "Non"}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">{coupon.maxUsage}</CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {coupon.maxPerUser}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">{coupon.comments}</CTableDataCell>

      <CTableDataCell className="p-3  align-items-center justify-content-center">
        <Link to={`/taxi/discounts/update/${coupon.id}`}>
          <EditIcon width="18" height="18" fill={colors.secondary} />
        </Link>{" "}
        <button
          onClick={onDelete}
          className="outline-none border-0 bg-transparent"
        >
          <DeleteIcon
            width="20"
            height="20"
            fill={colors.primary}
            className="ms-2"
          />
        </button>
      </CTableDataCell>
    </CTableRow>
  );
}

export default function TaxiCouponsTable({
  coupons,
  TaxiCouponsTableToBeDeleted,
  deleteCoupon,
  isDeleting
}: TaxiCouponsTableProps) {
  const [couponToBeDeleted, setCouponToBeDeleted] = useState<Coupon | null>(
    null
  );

  useEffect(() => {
    if (!isDeleting) {
      setCouponToBeDeleted(null);
    }
  }, [isDeleting]);

  return (
    <div className="mt-3">
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Nom{" "}
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm ">
              <div className="d-flex align-items-center justify-content-between">
                Date d&apos;expiration{" "}
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm ">
              <div className="d-flex align-items-center justify-content-between">
                Is active
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm ">
              <div className="d-flex align-items-center justify-content-between">
                Is Public
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Max usage
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Max usage per user
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Comments
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell
              scope="col"
              className="text-sm"
            ></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {coupons?.map(coupon => (
            <TableBodyRowContent
              key={coupon.id}
              coupon={coupon}
              deletedList={TaxiCouponsTableToBeDeleted}
              onDelete={() => setCouponToBeDeleted(coupon)}
            />
          ))}
        </CTableBody>
      </CTable>
      <PromptModal
        open={couponToBeDeleted !== null}
        onAccept={() => deleteCoupon(couponToBeDeleted?.id!)}
        onClose={() => setCouponToBeDeleted(null)}
        loading={isDeleting}
      />
    </div>
  );
}
