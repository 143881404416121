// @ts-nocheck
import { CFormCheck, CFormInput, CFormSelect, CSpinner } from "@coreui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import BoxWrapper from "../../../components/BoxWrapper";
import InfiniteScrollSelect from "../../../components/InfiniteScrollSelect";
import PasswordInput from "../../../components/inputs/PasswordInput";
import RichTextEditor from "../../../components/RichTextEditor";
import { FormMode } from "../../../helpers/enums";
import { verifyFileList } from "../../../helpers/general";
import { useAuth } from "../../../hooks/useAuth";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import { general } from "../../../locales/general";
import apiService from "../../../service/apiService";

interface placeData {
  name: string;
  categoryId: string;
  cityId: number;
  picture: File;
  logo: File;
  hasParking: string;
  priceRange: string[];
  paymentMethods: string[];
  mainType: string;
  secondaryType: string;
  speciality: string;
  chefName: string;
  averagePrice: number;
  keywords: string[];
  phone: string;
  email: string;
  website: string;
  facebookURL: string;
  instagramURL: string;
  address: string;
  latitude: number;
  longitude: number;
  placeAdmin: string;
  servicesSectionTitle: string;
  videoIds?: string[];
}

export default function NewPlace({ mode = FormMode.CREATE }) {
  const [formLoading, setFormLoading] = useState(false);
  const { isAdmin } = useAuth();
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const params = useParams();
  const navigate = useNavigate();
  const [description, setDescription] = useState("");

  const schema = yup.object({
    name: yup.string().required(),
    picture: yup.mixed().required("Ce champ est obligatoire"),
    logo: yup.mixed().required("Ce champ est obligatoire"),
    address: yup.string().required("Ce champ est obligatoire"),
    phone: yup.string().required("Ce champ est obligatoire"),
    // description: yup.string().notRequired(),
    googleMapUrl: yup.string().nullable(),
    emdedGoogleMapUrl: yup.string().nullable(),
    email: yup
      .string()
      .email("Veuillez saisir une adresse e-mail valide")
      .required("Ce champ est obligatoire"),
    website: yup
      .string()
      .url("Veuillez saisir une adresse URL valide")
      .nullable("Ce champ est obligatoire"),
    facebookURL: yup
      .string()
      .url("Veuillez saisir une adresse URL valide")
      .nullable(),
    instagramURL: yup
      .string()
      .url("Veuillez saisir une adresse URL valide")
      .nullable(),
    hasParking: yup.string().required("Ce champ est obligatoire"),
    priceRangeMin: yup
      .number()
      .nullable(true)
      .typeError()
      .transform((_, val) => (val ? Number(val) : null)),
    priceRangeMax: yup
      .number()
      .nullable(true)
      .typeError()
      .transform((_, val) => (val ? Number(val) : null)),
    // paymentMethods: yup.string().required("Ce champ est obligatoire"),
    keywords: yup.string().required("Ce champ est obligatoire"),
    categoryId: yup
      .object()
      .required("Ce champ est obligatoire")
      .typeError("Ce champ est obligatoire"),
    cityId: yup
      .object()
      .required("Ce champ est obligatoire")
      .typeError("Ce champ est obligatoire"),
    servicesSectionTitle: yup.string().required("Ce champ est obligatoire"),
    mainType: yup.string().nullable(),
    secondaryType: yup.string().nullable(),
    speciality: yup.string().nullable(),
    chefName: yup.string().nullable(),
    videoIds: yup.string().nullable(),
    // company fields
    companyName: yup.string().nullable(),
    companyAddress: yup.string().nullable(),
    IF: yup.string().nullable(),
    IC: yup.string().nullable(),
    rib: yup.string().nullable(),
    paymentMethodsInPlace: yup.boolean().nullable(),
    paymentMethodsCreditCard: yup.boolean().nullable(),
    paymentMethodsWafacash: yup.boolean().nullable(),
    paymentMethodsStripe: yup.boolean().nullable(),
    hoursStep: yup
      .number()
      .integer()
      .positive()
      .required("Ce champ est obligatoire")
      .typeError("Ce champ est obligatoire"),
    // end company fields
    // minNumberOfPersons: yup.number().default(1),
    // stepOfNumberOfPersons: yup.number().default(1),
    ...(isAdmin
      ? {
          averagePrice: yup
            .number()
            .nullable(true)
            .typeError()
            .transform((_, val) => (val ? Number(val) : null)),
          // config fields
          supportTableSelection: yup
            .string()
            .required("Ce champ est obligatoire"),
          supportReservations: yup
            .string()
            .required("Ce champ est obligatoire"),
          authConfirmBookings: yup
            .string()
            .required("Ce champ est obligatoire"),
          activateTVA: yup.string().required("Ce champ est obligatoire"),
          isVisible: yup.string().required("Ce champ est obligatoire"),
          // PlaceAdmin fields
          adminFullName: yup.string().required("Ce champ est obligatoire"),
          adminEmail: yup
            .string()
            .email("Veuillez saisir une adresse e-mail valide")
            .required("Ce champ est obligatoire"),
          adminPhone: yup.string().required("Ce champ est obligatoire"),
          ...(mode === FormMode.CREATE
            ? {
                adminPassword: yup.string().required("Ce champ est obligatoire")
              }
            : {})
        }
      : {})
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control: formControl,
    setValue: setFormValue,
    getValues: getFormValue,
    watch
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      hoursStep: 30,
      minNumberOfPersons: 1,
      stepOfNumberOfPersons: 1
    }
  });

  console.log("pass", watch("adminPassword"));

  useEffect(() => {
    if (mode === FormMode.UPDATE && params?.id) {
      const formFields = Object.keys(schema.fields);
      const placeGetRequest = apiService.MakeGetRequest(
        `places/${params.id}`,
        token
      );
      const placeAdminGetRequest = apiService.MakeGetRequest(
        `places/${params.id}/place-admin`,
        token
      );

      Promise.all([placeGetRequest, placeAdminGetRequest]).then(
        ([placeResp, placeAdminResp]) => {
          const paymentOptions = placeResp.paymentOptions ?? [];
          if (paymentOptions?.includes("in_place")) {
            setFormValue("paymentMethodsInPlace", true);
          }
          if (paymentOptions?.includes("credit_card")) {
            setFormValue("paymentMethodsCreditCard", true);
          }
          if (paymentOptions?.includes("wafacash")) {
            setFormValue("paymentMethodsWafacash", true);
          }
          if (paymentOptions?.includes("stripe")) {
            setFormValue("paymentMethodsStripe", true);
          }
          // placeAdmin fields
          setFormValue("adminFullName", placeAdminResp.fullName);
          setFormValue("adminEmail", placeAdminResp.email);
          setFormValue("adminPhone", placeAdminResp.phone);
          // place fields
          setFormValue("picture", placeResp.image);
          setFormValue("logo", placeResp.logoImage);
          setFormValue("categoryId", placeResp.category);
          setFormValue("cityId", placeResp.city);
          setDescription(placeResp?.description);
          // setFormValue("keywords", placeResp.keywords?.join(","));
          setFormValue("paymentMethods", placeResp.paymentMethods?.join(", "));
          setFormValue("priceRangeMin", placeResp.priceRange?.[0] ?? null);
          setFormValue("priceRangeMax", placeResp.priceRange?.[1] ?? null);
          Object.entries(placeResp).forEach(entry => {
            if (formFields.indexOf(entry[0]) !== -1) {
              if (
                entry[0] === "paymentMethods" ||
                entry[0] === "keywords" ||
                entry[0] === "videoIds"
              ) {
                setFormValue(entry[0], entry[1]?.join(","));
              } else {
                setFormValue(entry[0], entry[1]);
              }
            }
          });
        }
      );
    }
  }, [params?.id]);

  const createPlaceMutation = useMutation(async placeData => {
    return await apiService.MakePostRequest("places", placeData, token, true);
  });

  const updatePlaceMutation = useMutation(async placeData => {
    return await apiService.MakePutRequest(
      `places/${params.id}`,
      placeData,
      token,
      true
    );
  });

  const updatePlaceAdminMutation = useMutation(async placeAdminData => {
    return await apiService.MakePutRequest(
      `places/${params.id}/place-admin`,
      placeAdminData,
      token
    );
  });

  const createPlace = async data => {
    try {
      setFormLoading(true);
      await createPlaceMutation.mutateAsync(data);
      toastSuccess(general.fr.message.placeCreated);
      navigate(-1);
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const updatePlace = async data => {
    try {
      setFormLoading(true);
      const updatePlaceReq = updatePlaceMutation.mutateAsync(data);
      const updatePlaceAdminReq = updatePlaceAdminMutation.mutateAsync({
        fullName: data.adminFullName,
        email: data.adminEmail,
        phone: data.adminPhone
      });
      if (isAdmin) {
        await Promise.all([updatePlaceReq, updatePlaceAdminReq]);
        toastSuccess(general.fr.message.placeUpdated);
        navigate(-1);
      } else {
        await updatePlaceReq;
        toastSuccess(general.fr.message.placeUpdated);
        navigate(-1);
      }
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const onSubmit = async dataArg => {
    const data = { ...dataArg };
    try {
      const imageFile = verifyFileList(data.picture);
      data.picture = imageFile;
      const logoFile = verifyFileList(data.logo);
      data.logo = logoFile;
      data.categoryId = data.categoryId.id;
      data.latitude = data.cityId.latitude;
      data.longitude = data.cityId.longitude;
      data.priceRange = [data.priceRangeMin, data.priceRangeMax];
      data.cityId = data.cityId.id;
      const paymentOptions = [];
      if (data.paymentMethodsInPlace) {
        paymentOptions.push("in_place");
      }
      if (data.paymentMethodsCreditCard) {
        paymentOptions.push("credit_card");
      }
      if (data.paymentMethodsWafacash) {
        paymentOptions.push("wafacash");
      }
      if (data.paymentMethodsStripe) {
        paymentOptions.push("stripe");
      }
      data.paymentOptions = JSON.stringify(paymentOptions);
      if (mode === FormMode.UPDATE) {
        updatePlace({ ...data, description });
      } else {
        data.placeAdmin = JSON.stringify({
          fullName: data.adminFullName,
          email: data.adminEmail,
          phone: data.adminPhone,
          password: data.adminPassword
        });
        createPlace({ ...data, description });
      }
    } catch (error) {
      setFormLoading(false);
      console.log(error);
      toastError(general.fr.message.operationFailed);
    }
  };

  return (
    <div className=" m-4  ">
      <BoxWrapper>
        <section className="p-4">
          <div className="border-bottom border-success">
            <h4>
              {mode === FormMode.UPDATE
                ? "Mise à jour"
                : "Ajouter un établissement"}
            </h4>
          </div>
          <form className="row form mt-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-md-6  my-3 ">
              <label htmlFor="name" className="d-block">
                Nom <span className="text-sm text-red">*</span>
              </label>
              <CFormInput
                type="text"
                className="custom-input"
                placeholder="Nom de Place"
                id="name"
                name="name"
                {...register("name")}
              />
              {errors.name?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.name.message}
                </div>
              )}
            </div>
            <div className="col-md-6  my-3 ">
              <label htmlFor="categoryId" className="d-block mb-2">
                Catégories <span className="text-sm text-red">*</span>
              </label>
              <Controller
                name="categoryId"
                control={formControl}
                render={({ field }) => {
                  return (
                    <InfiniteScrollSelect
                      name="categoryId"
                      id="categoryId"
                      isClearable={false}
                      error={errors?.categoryId?.message}
                      getOptionLabel={option => option?.label}
                      getOptionValue={option => option.id}
                      isOptionSelected={(option, selectedValue) => {
                        const isSelected =
                          option?.id === selectedValue?.[0]?.id;
                        return isSelected;
                      }}
                      url={{
                        path: "categories"
                      }}
                      {...field}
                    />
                  );
                }}
              />
              {errors.categoryId?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.categoryId.message}
                </div>
              )}
            </div>
            <div className="col-md-6  my-3 ">
              <label htmlFor="cityId" className="d-block mb-2">
                Ville <span className="text-sm text-red">*</span>
              </label>
              <Controller
                name="cityId"
                control={formControl}
                render={({ field }) => {
                  return (
                    <InfiniteScrollSelect
                      name="cityId"
                      id="cityId"
                      isClearable={false}
                      error={errors?.cityId?.message}
                      getOptionLabel={option => option?.label}
                      getOptionValue={option => option.id}
                      isOptionSelected={(option, selectedValue) => {
                        const isSelected =
                          option?.id === selectedValue?.[0]?.id;
                        return isSelected;
                      }}
                      url={{
                        path: "cities"
                      }}
                      formatOptions={(cities: City[]) =>
                        cities.sort((a, b) =>
                          a.label.localeCompare(b.label, undefined, {
                            sensitivity: "base",
                            usage: "sort"
                          })
                        )
                      }
                      {...field}
                    />
                  );
                }}
              />
              {errors.cityId?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.cityId.message}
                </div>
              )}
            </div>
            <div className="col-md-6  my-3 ">
              <label htmlFor="address" className="d-block ">
                Address <span className="text-sm text-red">*</span>
              </label>
              <CFormInput
                type="text"
                id="address"
                placeholder="address"
                name="address"
                className="custom-input"
                {...register("address")}
              />
              {errors.address?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.address.message}
                </div>
              )}
            </div>
            <div className="col-md-6  my-3">
              <label htmlFor="googleMapUrl" className="d-block">
                URL de la localisation
              </label>
              <CFormInput
                type="url"
                id="googleMapUrl"
                name="googleMapUrl"
                placeholder="url"
                className="custom-input"
                {...register("googleMapUrl")}
              />
              {errors.googleMapUrl?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.googleMapUrl.message}
                </div>
              )}
            </div>
            <div className="col-md-6  my-3">
              <label htmlFor="emdedGoogleMapUrl" className="d-block">
                Emded URL de la carte de Google
              </label>
              <CFormInput
                type="url"
                id="emdedGoogleMapUrl"
                name="emdedGoogleMapUrl"
                placeholder="url"
                className="custom-input"
                {...register("emdedGoogleMapUrl")}
              />
              {errors.emdedGoogleMapUrl?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.emdedGoogleMapUrl.message}
                </div>
              )}
            </div>
            <div className="col-md-6  my-3 ">
              <label htmlFor="picture" className="d-block">
                Grande image d'établissement
                <span className="text-sm text-red">*</span>
              </label>
              <CFormInput
                type="file"
                id="picture"
                name="picture"
                className="custom-input"
                required={mode === FormMode.UPDATE ? false : true}
                accept="image/png,image/jpeg,image/jpg"
                {...register("picture")}
              />
              {errors.picture?.message ? (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.picture.message}
                </div>
              ) : (
                <span className="text-xs text-danger">
                  (préférée 1425&times;520)
                </span>
              )}
            </div>
            <div className="col-md-6  my-3 ">
              <label htmlFor="logo" className="d-block">
                Petite image d'établissement{" "}
                <span className="text-sm text-red">*</span>
              </label>
              <CFormInput
                type="file"
                id="logo"
                name="logo"
                className="custom-input"
                required={mode === FormMode.UPDATE ? false : true}
                accept="image/png,image/jpeg,image/jpg"
                {...register("logo")}
              />
              {errors.logo?.message ? (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.logo.message}
                </div>
              ) : (
                <span className="text-xs text-danger">
                  (préférée 150&times;150)
                </span>
              )}
            </div>

            {/* <div className="col-md-6  my-3 ">
              <label htmlFor="videoIds" className="d-block">
                Les ids des vidéos
                <span className="text-xs text-danger">
                  {" "}
                  (Séparés par une virgule)
                </span>
              </label>
              <CFormInput
                type="text"
                className="custom-input"
                id="videoIds"
                name="videoIds"
                {...register("videoIds")}
              />
              {errors.videoIds?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.videoIds.message}
                </div>
              )}
            </div> */}

            <div className="col-md-6  my-3 ">
              <label htmlFor="hasParking" className="d-block">
                Parking <span className="text-sm text-red">*</span>
              </label>
              <CFormSelect
                name="hasParking"
                id="hasParking"
                {...register("hasParking")}
              >
                <option selected value="">
                  Choisissez une option
                </option>
                <option value="true">Oui</option>
                <option value="false">Non</option>
              </CFormSelect>
              {errors.hasParking?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.hasParking.message}
                </div>
              )}
            </div>

            <div className="col-md-6  my-3 ">
              <label htmlFor="priceRange" className="d-block">
                Gamme de prix
              </label>
              <div className="d-flex align-items-center gap-2">
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder="0.00"
                  id="priceRangeMin"
                  name="priceRangeMin"
                  {...register("priceRangeMin")}
                />
                -
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder="0.00"
                  id="priceRangeMax"
                  name="priceRangeMax"
                  {...register("priceRangeMax")}
                />
              </div>
            </div>

            <div className="col-md-6  my-3 ">
              <label htmlFor="paymentMethods" className="d-block">
                Les modes de paiement
                <span className="text-sm text-red">*</span>{" "}
              </label>
              <CFormInput
                type="text"
                className="custom-input"
                placeholder="Mode de paiement"
                id="paymentMethods"
                name="paymentMethods"
                {...register("paymentMethods")}
              />
              {errors.paymentMethods?.message ? (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.paymentMethods.message}
                </div>
              ) : (
                <span className="text-xs text-danger">
                  (Séparer par une virgule)
                </span>
              )}
            </div>

            <div className="col-md-6  my-3 ">
              <label htmlFor="mainType" className="d-block">
                Le menu principal
              </label>
              <CFormInput
                type="text"
                className="custom-input"
                placeholder="mainType"
                id="mainType"
                name="mainType"
                {...register("mainType")}
              />
              {errors.mainType?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.mainType.message}
                </div>
              )}
            </div>

            <div className="col-md-6  my-3 ">
              <label htmlFor="secondaryType" className="d-block">
                Le menu secondaire
              </label>
              <CFormInput
                type="text"
                className="custom-input"
                placeholder="SecondaryType"
                id="secondaryType"
                name="secondaryType"
                {...register("secondaryType")}
              />
              {errors.secondaryType?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.secondaryType.message}
                </div>
              )}
            </div>

            <div className="col-md-6  my-3 ">
              <label htmlFor="speciality" className="d-block">
                Spécialités
              </label>
              <CFormInput
                type="text"
                className="custom-input"
                placeholder="speciality"
                id="speciality"
                name="speciality"
                {...register("speciality")}
              />
              {errors.speciality?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.speciality.message}
                </div>
              )}
            </div>

            <div className="col-md-6  my-3 ">
              <label htmlFor="chefName" className="d-block">
                Nom du chef
              </label>
              <CFormInput
                type="text"
                className="custom-input"
                placeholder="Chef Name"
                id="chefName"
                name="chefName"
                {...register("chefName")}
              />
              {errors.chefName?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.chefName.message}
                </div>
              )}
            </div>

            <div className="col-md-6  my-3 ">
              <label htmlFor="averagePrice" className="d-block">
                Panier Moyen
              </label>
              <CFormInput
                type="number"
                className="custom-input"
                id="averagePrice"
                name="averagePrice"
                readOnly={!isAdmin}
                {...register("averagePrice")}
              />
              {errors.averagePrice?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.averagePrice.message}
                </div>
              )}
            </div>

            <div className="col-md-6  my-3 ">
              <label htmlFor="keywords" className="d-block">
                Mots-clés
                <span className="text-sm text-red">*</span>{" "}
              </label>
              <CFormInput
                type="text"
                className="custom-input"
                id="keywords"
                name="keywords"
                {...register("keywords")}
              />
              {errors.keywords?.message ? (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.keywords.message}
                </div>
              ) : (
                <span className="text-xs text-danger">
                  (Séparer par une virgule)
                </span>
              )}
            </div>

            <div className="col-md-6  my-3 ">
              <label htmlFor="Téléphone" className="d-block">
                Téléphone <span className="text-sm text-red">*</span>
              </label>
              <CFormInput
                type="text"
                className="custom-input"
                placeholder="Téléphone"
                id="phone"
                name="phone"
                {...register("phone")}
              />
              {errors.phone?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.phone.message}
                </div>
              )}
            </div>
            <div className="col-md-6  my-3 ">
              <label htmlFor="servicesSectionTitle" className="d-block">
                Titre de la section service{" "}
                <span className="text-sm text-red">*</span>
              </label>
              <CFormInput
                type="text"
                className="custom-input"
                id="servicesSectionTitle"
                name="servicesSectionTitle"
                {...register("servicesSectionTitle")}
              />
              {errors.servicesSectionTitle?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.servicesSectionTitle.message}
                </div>
              )}
            </div>
            <div className="col-md-6  my-3 ">
              <label htmlFor="email" className="d-block">
                Email <span className="text-sm text-red">*</span>
              </label>
              <CFormInput
                type="email"
                className="custom-input"
                autoComplete="new-email"
                id="email"
                name="email"
                {...register("email")}
              />
              {errors.email?.message && (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.email.message}
                </div>
              )}
            </div>

            <div className="col-md-6  my-3 ">
              <label htmlFor="website" className="d-block">
                Site web
                <br />
              </label>
              <CFormInput
                type="url"
                className="custom-input"
                placeholder="Website"
                id="website"
                name="website"
                {...register("website")}
              />
              {errors.website?.message ? (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.website.message}
                </div>
              ) : (
                <span className="text-xs text-danger fst-italic">
                  {" "}
                  (Exemple : https://www.exemple.com/ )
                </span>
              )}
            </div>
            <div className="col-md-6  my-3 ">
              <label htmlFor="facebookURL" className="d-block">
                Facebook
              </label>
              <CFormInput
                type="url"
                pattern="https://.*"
                className="custom-input"
                placeholder="Facebook"
                id="facebookURL"
                name="facebookURL"
                {...register("facebookURL")}
              />
              {errors.facebookURL?.message ? (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.facebookURL.message}
                </div>
              ) : (
                <span className="text-xs text-danger fst-italic">
                  {" "}
                  (Exemple : https://www.Facebook.com/exemple )
                </span>
              )}
            </div>

            <div className="col-md-6  my-3 ">
              <label htmlFor="instagramURL" className="d-block">
                Instagram
                <br />
              </label>
              <CFormInput
                type="url"
                pattern="https://.*"
                className="custom-input"
                placeholder="Instagram"
                id="instagramURL"
                name="instagramURL"
                {...register("instagramURL")}
              />
              {errors.instagramURL?.message ? (
                <div
                  className="text-red-500 text-opacity-50"
                  style={{ color: "red" }}
                >
                  {errors.instagramURL.message}
                </div>
              ) : (
                <span className="text-xs text-danger fst-italic">
                  {" "}
                  (Exemple : https://www.Instagram.com/exemple )
                </span>
              )}
            </div>

            <div className="my-3">
              <label htmlFor="description">Description</label>
              <RichTextEditor
                onChange={setDescription}
                // {...register("description")}
                name="description"
                defaultValue={description}
              />
            </div>

            {!isAdmin ? null : (
              <section className="mt-3 border pt-3 rounded-sm">
                <div className="">
                  <h5>Configuration</h5>
                </div>
                <div className="row form ">
                  <div className="col-md-6 col-lg-4 my-4">
                    <label htmlFor="supportTableSelection" className="d-block">
                      Sélection de la table
                      <span className="text-sm text-red"> *</span>
                    </label>
                    <CFormSelect
                      name="supportTableSelection"
                      id="supportTableSelection"
                      {...register("supportTableSelection")}
                    >
                      <option value="true">Oui</option>
                      <option value="false">Non</option>
                    </CFormSelect>
                    {errors.supportTableSelection?.message && (
                      <div
                        className="text-red-500 text-opacity-50"
                        style={{ color: "red" }}
                      >
                        {errors.supportTableSelection.message}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 col-lg-4 my-4 ">
                    <label htmlFor="supportReservations" className="d-block">
                      Autorisez la réservation
                      <span className="text-sm text-red"> *</span>
                    </label>
                    <CFormSelect
                      name="supportReservations"
                      id="supportReservations"
                      {...register("supportReservations")}
                    >
                      <option value="true">Oui</option>
                      <option value="false">Non</option>
                    </CFormSelect>
                    {errors.supportReservations?.message && (
                      <div
                        className="text-red-500 text-opacity-50"
                        style={{ color: "red" }}
                      >
                        {errors.supportReservations.message}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 col-lg-4 my-4 ">
                    <label htmlFor="activateTVA" className="d-block">
                      Activer TVA
                      <span className="text-sm text-red"> *</span>
                    </label>
                    <CFormSelect
                      name="activateTVA"
                      id="activateTVA"
                      {...register("activateTVA")}
                    >
                      <option value="true">Oui</option>
                      <option value="false">Non</option>
                    </CFormSelect>
                    {errors.activateTVA?.message && (
                      <div
                        className="text-red-500 text-opacity-50"
                        style={{ color: "red" }}
                      >
                        {errors.activateTVA.message}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-6 col-lg-4 my-4 ">
                    <label htmlFor="authConfirmBookings" className="d-block">
                      Confirmer automatiquement les réservations
                      <span className="text-sm text-red"> *</span>
                    </label>
                    <CFormSelect
                      name="authConfirmBookings"
                      id="authConfirmBookings"
                      {...register("authConfirmBookings")}
                    >
                      <option value="true">Oui</option>
                      <option value="false">Non</option>
                    </CFormSelect>
                    {errors.authConfirmBookings?.message && (
                      <div
                        className="text-red-500 text-opacity-50"
                        style={{ color: "red" }}
                      >
                        {errors.authConfirmBookings.message}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-6 col-lg-4 my-4 ">
                    <label htmlFor="isVisible" className="d-block">
                      Visible
                      <span className="text-sm text-red"> *</span>
                    </label>
                    <CFormSelect
                      name="isVisible"
                      id="isVisible"
                      {...register("isVisible")}
                    >
                      <option value="true">Oui</option>
                      <option value="false">Non</option>
                    </CFormSelect>
                    <div
                      className="text-red-500 text-opacity-50"
                      style={{
                        fontSize: "12px",
                        color: "grey"
                      }}
                    >
                      Pour contrôler si l'établissement est visible dans
                      l'application et le site Web
                    </div>
                    {errors.isVisible?.message && (
                      <div
                        className="text-red-500 text-opacity-50"
                        style={{ color: "red" }}
                      >
                        {errors.isVisible.message}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-6 col-lg-4 my-4 ">
                    <label htmlFor="hoursStep" className="d-block">
                      Étape entre les heures
                      <span className="text-sm text-red"> *</span>
                    </label>
                    <input
                      className="custom-input"
                      type="number"
                      name="hoursStep"
                      id="hoursStep"
                      {...register("hoursStep")}
                    />
                    <div
                      className="text-red-500 text-opacity-50"
                      style={{
                        fontSize: "12px",
                        color: "grey"
                      }}
                    >
                      L'intervalle de temps en minutes entre les heures de
                      réservation disponibles (par exemple, 30 pour 30 minutes)
                    </div>
                    {errors.hoursStep?.message && (
                      <div
                        className="text-red-500 text-opacity-50"
                        style={{ color: "red" }}
                      >
                        {errors.hoursStep.message}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-6 col-lg-4 my-4 ">
                    <label className="d-block">
                      Méthodes de payement
                      <span className="text-sm text-red"> *</span>
                    </label>
                    <div>
                      <CFormCheck
                        label="Carte bancaire"
                        id="paymentMethodsCreditCard"
                        {...register("paymentMethodsCreditCard")}
                      />
                      <CFormCheck
                        label="Wafacash"
                        id="paymentMethodsWafacash"
                        {...register("paymentMethodsWafacash")}
                      />
                      <CFormCheck
                        label="Sur place"
                        id="paymentMethodsInPlace"
                        {...register("paymentMethodsInPlace")}
                      />
                      <CFormCheck
                        label="Stripe"
                        id="paymentMethodsStripe"
                        {...register("paymentMethodsStripe")}
                      />
                    </div>
                  </div>
                  {/* <div className="col-sm-6 col-lg-4 my-4 ">
                    <label htmlFor="minNumberOfPersons" className="d-block">
                    Nombre minimum de personnes
                      <span className="text-sm text-red"> *</span>
                    </label>
                    <input
                      className="custom-input"
                      type="number"
                      name="minNumberOfPersons"
                      id="minNumberOfPersons"
                      {...register("minNumberOfPersons")}
                    />
                    <div
                      className="text-red-500 text-opacity-50"
                      style={{
                        fontSize: "12px",
                        color: "grey"
                      }}
                    >
                    </div>
                    {errors.minNumberOfPersons?.message && (
                      <div
                        className="text-red-500 text-opacity-50"
                        style={{ color: "red" }}
                      >
                        {errors.minNumberOfPersons.message}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-6 col-lg-4 my-4 ">
                    <label htmlFor="stepOfNumberOfPersons" className="d-block">
                      Étape entre le nombre de personnes
                      <span className="text-sm text-red"> *</span>
                    </label>
                    <input
                      className="custom-input"
                      type="number"
                      name="stepOfNumberOfPersons"
                      id="stepOfNumberOfPersons"
                      {...register("stepOfNumberOfPersons")}
                    />
                    <div
                      className="text-red-500 text-opacity-50"
                      style={{
                        fontSize: "12px",
                        color: "grey"
                      }}
                    >
                    </div>
                    {errors.stepOfNumberOfPersons?.message && (
                      <div
                        className="text-red-500 text-opacity-50"
                        style={{ color: "red" }}
                      >
                        {errors.stepOfNumberOfPersons.message}
                      </div>
                    )}
                  </div> */}
                </div>
              </section>
            )}

            <section className="mt-3 border pt-3 rounded-sm">
              <div className="">
                <h5>Informations de la société</h5>
              </div>
              <div className="row form ">
                <div className="col-md-6 col-lg-4 my-4 ">
                  <label htmlFor="companyName" className="d-block">
                    Nom de la société
                  </label>
                  <CFormInput
                    type="text"
                    className="custom-input"
                    placeholder="Nom de la société"
                    id="companyName"
                    name="companyName"
                    {...register("companyName")}
                  />
                  {errors.companyName?.message && (
                    <div
                      className="text-red-500 text-opacity-50"
                      style={{ color: "red" }}
                    >
                      {errors.companyName.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6 col-lg-4 my-4 ">
                  <label htmlFor="companyAddress" className="d-block">
                    Address de la société
                  </label>
                  <CFormInput
                    type="text"
                    className="custom-input"
                    placeholder="Address de la société"
                    id="companyAddress"
                    name="companyAddress"
                    {...register("companyAddress")}
                  />
                  {errors.companyAddress?.message && (
                    <div
                      className="text-red-500 text-opacity-50"
                      style={{ color: "red" }}
                    >
                      {errors.companyAddress.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6 col-lg-4 my-4 ">
                  <label htmlFor="IF" className="d-block">
                    IF
                  </label>
                  <CFormInput
                    type="text"
                    className="custom-input"
                    placeholder="IF"
                    id="IF"
                    name="IF"
                    {...register("IF")}
                  />
                  {errors.IF?.message && (
                    <div
                      className="text-red-500 text-opacity-50"
                      style={{ color: "red" }}
                    >
                      {errors.IF.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6 col-lg-4 my-4 ">
                  <label htmlFor="IC" className="d-block">
                    IC
                  </label>
                  <CFormInput
                    type="text"
                    className="custom-input"
                    placeholder="IC"
                    id="IC"
                    name="IC"
                    {...register("IC")}
                  />
                  {errors.IC?.message && (
                    <div
                      className="text-red-500 text-opacity-50"
                      style={{ color: "red" }}
                    >
                      {errors.IC.message}
                    </div>
                  )}
                </div>
                <div className="col-md-6 col-lg-4 my-4 ">
                  <label htmlFor="rib" className="d-block">
                    RIB
                  </label>
                  <CFormInput
                    type="text"
                    className="custom-input"
                    placeholder="RIB"
                    id="rib"
                    name="rib"
                    {...register("rib")}
                  />
                  {errors.rib?.message && (
                    <div
                      className="text-red-500 text-opacity-50"
                      style={{ color: "red" }}
                    >
                      {errors.rib.message}
                    </div>
                  )}
                </div>
              </div>
            </section>

            {!isAdmin ? null : (
              <section className="mt-5">
                <div className="border-bottom border-success">
                  <h4>Établissement admin</h4>
                </div>
                <div className="row form ">
                  <div className="col-md-6 my-4 ">
                    <label htmlFor="adminFullName" className="d-block">
                      Nom et Prénom
                      <span className="text-sm text-red"> *</span>
                    </label>
                    <CFormInput
                      type="text"
                      className="custom-input"
                      placeholder=" Nom et Prénom"
                      id="adminFullName"
                      name="adminFullName"
                      {...register("adminFullName")}
                    />
                    {errors.adminFullName?.message && (
                      <div
                        className="text-red-500 text-opacity-50"
                        style={{ color: "red" }}
                      >
                        {errors.adminFullName.message}
                      </div>
                    )}
                  </div>

                  <div className="col-md-6 my-4 ">
                    <label htmlFor="adminEmail" className="d-block">
                      Email <span className="text-sm text-red">*</span>
                    </label>
                    <CFormInput
                      type="email"
                      className="custom-input"
                      placeholder="Email"
                      id="adminEmail"
                      name="adminEmail"
                      autoComplete="new-admin-email"
                      {...register("adminEmail")}
                    />
                    {errors.adminEmail?.message && (
                      <div
                        className="text-red-500 text-opacity-50"
                        style={{ color: "red" }}
                      >
                        {errors.adminEmail.message}
                      </div>
                    )}
                  </div>

                  <div className="col-md-6 my-4 ">
                    <label htmlFor="adminPhone" className="d-block">
                      Téléphone <span className="text-sm text-red">*</span>
                    </label>
                    <CFormInput
                      type="text"
                      className="custom-input"
                      placeholder="Téléphone"
                      id="adminPhone"
                      name="adminPhone"
                      {...register("adminPhone")}
                    />
                    {errors.adminPhone?.message && (
                      <div
                        className="text-red-500 text-opacity-50"
                        style={{ color: "red" }}
                      >
                        {errors.adminPhone.message}
                      </div>
                    )}
                  </div>

                  {mode === FormMode.CREATE ? (
                    <div className="col-md-6 my-4 ">
                      {/* <CFormInput
                        type="password"
                        className="custom-input"
                        placeholder=" Mot de passe"
                        id="adminPassword"
                        name="adminPassword"
                        
                      /> */}
                      <PasswordInput
                        isRequired
                        className="custom-input"
                        placeholder="Mot de passe"
                        id="adminPassword"
                        name="adminPassword"
                        autoComplete="new-password"
                        onChange={val =>
                          setFormValue("adminPassword", val?.target?.value)
                        }
                        labelText=" Mot de passe"
                      />
                      {errors.adminPassword?.message && (
                        <div
                          className="text-red-500 text-opacity-50"
                          style={{ color: "red" }}
                        >
                          {errors.adminPassword.message}
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              </section>
            )}

            <section className="d-flex justify-content-center buttons gap-4 mt-5">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
              >
                Annuler
              </button>
              <button
                className="btn btn-success green-shadow w-20 text-white"
                type="submit"
                disabled={formLoading}
              >
                {formLoading ? (
                  <div className="text-center">
                    <CSpinner size="sm" />
                  </div>
                ) : (
                  "Enregistrer"
                )}
              </button>
            </section>
          </form>
        </section>
      </BoxWrapper>
    </div>
  );
}
