import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import BoxWrapper from "../../components/BoxWrapper";
import AdminRoute from "../auth/RestrictedRoute";
import { SidebarIcon } from "../../components/icons/SidebarIcons";
import { pluralize } from "../../helpers/general";
import RenderTable from "../../components/RenderTable";
import { useReducer, useState } from "react";
import Pagination from "../../components/Pagination";
import { useRides } from "../../hooks/useRides";
import { general } from "../../locales/general";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../context/bulkDeleteReducer";
import RidersTable from "./RidersTable";
import { RideStatus, RideStatusLabels } from "../../helpers/enums";
import DateFilters from "../../components/DateFilters";

const pageSize = 10;

export default function Riders() {
  const { queries, addQuery, isLoading, rides, setDates } = useRides();
  const [ridesToBeDeleted, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );

  return (
    <AdminRoute>
      <section>
        <h5 className="fs-4 fw-bold mb-2">Trajets</h5>
        <BoxWrapper>
          <div className="d-flex  justify-content-between align-items-baseline before-table">
            <div className="d-flex align-items-center gap-4 p-3 delete-add"></div>
            <div
              className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
              style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
            >
              <SidebarIcon.RidesSVG className="fill-white" />
              <p className="fs-5 fw-bold m-0  text-white text-pre">
                {rides?.meta?.totalItems}{" "}
                {pluralize("Trajet", rides?.meta?.totalItems)}
              </p>
            </div>
          </div>

          <div className="d-flex align-items-center gap-4 px-3 mb-3">
            <DateFilters label="Date" onChange={setDates} />
            <div>
              <label htmlFor="status">Statut</label>
              <select
                name="status"
                id="status"
                onChange={e => addQuery("status", e.target.value as RideStatus)}
              >
                <option value={""}>Tous</option>
                {Object.entries(RideStatusLabels).map(([key, value]) => (
                  <option value={key}>{value}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="status">Mode de paiement</label>
              <select
                name="status"
                id="status"
                onChange={e => addQuery("paymentMethod", e.target.value as any)}
              >
                <option value="">Tous</option>
                {["cash"].map(value => (
                  <option value={value}>{value}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="#"></label>
              <div className="search-form">
                <div className=" input-field">
                  <MagnifyingGlassIcon color="gray" width={20} />
                  <input
                    type="text"
                    className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                    placeholder="Rechercher"
                    onChange={e => addQuery("query", e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="table-responsive overflow-auto">
            <RenderTable
              loading={isLoading}
              render={() =>
                rides?.items && rides?.items?.length > 0 ? (
                  <RidersTable
                    riders={rides?.items}
                    onRiderSelect={dispatch}
                    selectedRides={ridesToBeDeleted.ids}
                  />
                ) : (
                  <p className="text-center fs-5 my-2">{general.fr.noData}</p>
                )
              }
            />
          </div>
        </BoxWrapper>
        <Pagination
          currentPage={queries.page}
          onPageChange={page => {
            addQuery("page", page);
          }}
          siblingCount={1}
          totalCount={rides?.meta?.totalPages || 0}
          pageSize={pageSize}
        />
      </section>
    </AdminRoute>
  );
}
