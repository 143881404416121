import React, { useState, useEffect, useMemo } from "react";
import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from "react-leaflet";
import L from "leaflet";
import { getFirestore, collection, onSnapshot, doc as firebaseDocument, getDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/styles";
import ReactLeafletDriftMarker from "react-leaflet-drift-marker"
import * as FirebaseAdmin from "firebase-admin";
import {
  CCloseButton,
  CFormCheck,
  COffcanvas,
  COffcanvasBody,
  COffcanvasHeader
} from "@coreui/react";
import TaxiDetails from "./TaxiDetails";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Controller, useForm } from "react-hook-form";
import RestrictedRoute from "../../auth/RestrictedRoute";
import { cityBoundaries } from "../../../helpers/constants";
import { pluralize } from "../../../helpers/general";
import { SidebarIcon } from "../../../components/icons/SidebarIcons";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Icon setup
// const carIcon = new L.Icon({
//   iconUrl: "/taxi_red_map_icon.png",
//   iconSize: [25, 35],

// });



const cityStyle = {
  fillColor: "#FF7722",
  weight: 2,
  opacity: 1,
  color: "#FF7722",
  fillOpacity: 0.08
};

export default function AllTaxi() {
  const [driverPingStaleTimeout, setDriverPingStaleTimeout] = useState<MilliSeconds>(90000);
  const [taxis, setTaxis] = useState<FireStoreDriverLocation[]>([]);
  const [
    selectedTaxi,
    setSelectedTaxi
  ] = useState<FireStoreDriverLocation | null>(null);
  const [showDetails, setShowDetails] = useState(false);
  const { control, watch } = useForm();


  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, process.env.REACT_APP_FIREBASE_FIRESTORE_DB!),
      snapshot => {
        const docs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const taxiData: any = docs.filter(
          (t: any) => t.curr_loc?.latitude && t.curr_loc?.longitude
        );
        setTaxis(taxiData);
      }
    );
    const configDocRef = firebaseDocument(db, process.env.REACT_APP_TAXI_CONFIG_FIRESTORE_DOC!);
    getDoc(configDocRef).then(doc => {
      const data = doc.data();
      if (data) {
        setDriverPingStaleTimeout(data.driverPingStale_ms);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleTaxiClick = (taxi: FireStoreDriverLocation) => {
    setSelectedTaxi(taxi);
    setShowDetails(true);
  };



  const onClusterClick = (event: any) => {
    // Make sure the clicked cluster gets spiderfied
    const clickedCluster = event.layer;
    if (!clickedCluster.spiderfied) {
      clickedCluster.spiderfy();
    }
  };

  const status = watch("statut") || "online";

  const filteredTaxis = useMemo(() => {

    if (!status || status === "all") return taxis;
    if (status === "active") {
      return taxis.filter(taxi => taxi.isOnline && taxi.ping_timestamp.toDate().getTime() + driverPingStaleTimeout > Date.now());
    }
    const isOnline = status === "online";
    return taxis.filter(taxi => taxi.isOnline === isOnline);
  }, [status, taxis]);

  if (taxis.length === 0) return null;


  // console.log(filteredTaxis);
  return (
    <RestrictedRoute>
      <div style={{ position: "relative", height: "100vh" }}>
        <div className="d-flex justify-content-between align-items-center mt-2">
          <div className="d-flex align-content-center gap-3">
            <p>Statut:</p>
            <Controller
              name="statut"
              control={control}
              render={({ field }) => (
                <CFormCheck
                  type="radio"
                  {...field}
                  id="statut0"
                  label="Tout"
                  value={"all"}
                  checked={status === "all"}
                />
              )}
            />
            <Controller
              name="statut"
              control={control}
              render={({ field }) => (
                <CFormCheck
                  type="radio"
                  {...field}
                  id="statut1"
                  label="En ligne"
                  value={"online"}
                  checked={status === "online"}
                />
              )}
            />
            <Controller
              name="statut"
              control={control}
              render={({ field }) => (
                <CFormCheck
                  type="radio"
                  {...field}
                  id="statut2"
                  label="Hors connexion"
                  value={"offline"}
                  checked={status === "offline"}
                />
              )}
            />
            <Controller
              name="statut"
              control={control}
              render={({ field }) => (
                <CFormCheck
                  type="radio"
                  {...field}
                  id="statut3"
                  label="Active"
                  value={"active"}
                  checked={status === "active"}
                />
              )}
            />
          </div>
          <div className="d-flex  justify-content-between align-items-baseline before-table">
            <div className="d-flex align-items-center gap-4 p-20 delete-add"></div>
            <div
              className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-2"
              style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
            >
              <SidebarIcon.TaxisSVG className="fill-white" />
              <p className="fs-5 fw-bold m-0  text-white text-pre">
                {filteredTaxis.length}{" "}
                {pluralize("Taxi")}
              </p>
            </div>
          </div>
        </div>
        <MapContainer
          center={[30.427755, -9.598107]} // Default center
          zoom={13}
          style={{ height: "100%", width: "100%", zIndex: 40 }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
          />

          <GeoJSON data={cityBoundaries} style={cityStyle} />
          {/* <MarkerClusterGroup onClusterClick={onClusterClick} spiderfyOnMaxZoom> */}
          {filteredTaxis.map(taxi => (
            <ReactLeafletDriftMarker
              key={taxi?.id}
              position={{
                lat: taxi?.curr_loc?.latitude,
                lng: taxi?.curr_loc?.longitude
              }}

              icon={L.divIcon({
                className: 'custom-icon',
                html: `<div style="transform: rotate(${taxi?.rotation || 0}deg)">
                          <img src="/taxi_red_map_icon.png" width="25" height="35"/>
                         </div>`,

                iconAnchor: [12.5, 90]
              })}
              // icon={carIcon}
              duration={3000}
              eventHandlers={{
                click: e => {
                  e.originalEvent.stopPropagation();
                  handleTaxiClick(taxi);
                }
              }}
            >

              <Popup>{taxi?.isOnline ? "En ligne" : "Hors connexion"}</Popup>
            </ReactLeafletDriftMarker>
          ))}

          {/* </MarkerClusterGroup> */}

        </MapContainer>

        <COffcanvas
          placement="start"
          visible={showDetails}
          onHide={() => setShowDetails(false)}
          backdrop={false}
          scroll={true}
        >
          <COffcanvasHeader>
            <h5>Détails du taxi</h5>
            <CCloseButton
              className="text-reset"
              onClick={() => setShowDetails(false)}
            />
          </COffcanvasHeader>
          <COffcanvasBody>
            {selectedTaxi ? <TaxiDetails selectedTaxi={selectedTaxi} /> : null}
          </COffcanvasBody>
        </COffcanvas>
      </div>
    </RestrictedRoute >
  );
}
