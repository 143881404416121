import { useMemo, useReducer, useState } from "react";
import DateFilters from "../../components/DateFilters";
import Pagination from "../../components/Pagination";
import RenderTable from "../../components/RenderTable";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../context/bulkDeleteReducer";
import { RideStatus, RideStatusLabels } from "../../helpers/enums";
import { useRides } from "../../hooks/useRides";
import { general } from "../../locales/general";
import RidersTable from "../taxi/RidersTable";
import RideBonus from "./RideBonus";
import { CModal } from "@coreui/react";
import PayRideModalContent from "./PayRideModalContent";

type DriverRidesProps = {
  driver: Driver | undefined;
};
const pageSize = 10;

type ModalState = {
  visible: boolean;
  target: "discount" | "bonus" | null;
};

export default function DriverRides({ driver }: DriverRidesProps) {
  const [selectedRides, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );
  const defaultOption = useMemo(
    () => ({
      driverId: driver?.id
    }),
    [driver?.id]
  );

  const [modalState, setModalState] = useState<ModalState>({
    visible: false,
    target: null
  });

  const { queries, addQuery, isLoading, rides, setDates } = useRides(
    defaultOption
  );

  if (!driver) return null;

  return (
    <div className="p-4">
      {/* <RideBonus
        driverId={driver.id}
        rides={selectedRides.ids}
        clearSelectedRides={() => dispatch({ type: "", id: "" })}
      /> */}
      <div className="d-flex flex-wrap align-items-center gap-4 px-3 mt-4 mb-3">
        <DateFilters label="Date" onChange={setDates} />
        <div>
          <label htmlFor="status">Statut</label>
          <select
            name="status"
            id="status"
            onChange={e => addQuery("status", e.target.value as RideStatus)}
          >
            <option value={""}>Tous</option>
            {Object.entries(RideStatusLabels).map(([key, value]) => (
              <option value={key}>{value}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="status">Mode de paiement</label>
          <select
            name="status"
            id="status"
            onChange={e => addQuery("paymentMethod", e.target.value as any)}
          >
            <option value="">Tous</option>
            {["cash"].map(value => (
              <option value={value}>{value}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="isPayed" className="ms-1">
            Payé ?
          </label>
          <select
            name="isPayed"
            id="isPayed"
            onChange={e => addQuery("payed", e.target.value as any)}
          >
            <option value={""}>Tous</option>
            <option value="true">Payé</option>
            <option value="false">Non payé</option>
          </select>
        </div>
        <div>
          <label htmlFor="isValid" className="ms-1">
            Le trajet est-il valid ?
          </label>
          <select
            name="isValid"
            id="isValid"
            onChange={e => addQuery("isValidRide", e.target.value as any)}
          >
            <option value={""}>Tous</option>
            <option value="true">Valide</option>
            <option value="false">Non valide</option>
          </select>
        </div>
      </div>

      <div className="d-flex align-items-center gap-4 mt-5">
        <button
          className="btn btn-danger rounded-sm"
          type="button"
          disabled={selectedRides.ids.length === 0}
          onClick={() =>
            setModalState({ ...modalState, visible: true, target: "discount" })
          }
        >
          Payer les frais de coupon
        </button>
        <button
          className="btn btn-danger rounded-sm"
          type="button"
          disabled={selectedRides.ids.length === 0}
          onClick={() =>
            setModalState({ ...modalState, visible: true, target: "bonus" })
          }
        >
          Payer le bonus de trajet
        </button>
      </div>

      <div className="table-responsive overflow-auto">
        <RenderTable
          loading={isLoading}
          render={() =>
            rides?.items && rides?.items?.length > 0 ? (
              <RidersTable
                riders={rides?.items}
                onRiderSelect={dispatch}
                selectedRides={selectedRides.ids}
                showDriver={false}
              />
            ) : (
              <p className="text-center fs-5 my-2">{general.fr.noData}</p>
            )
          }
        />
        <Pagination
          currentPage={queries.page}
          onPageChange={page => {
            addQuery("page", page);
          }}
          siblingCount={1}
          totalCount={rides?.meta?.totalPages || 0}
          pageSize={pageSize}
        />
      </div>
      <CModal
        alignment="center"
        visible={modalState.visible}
        onClose={() => setModalState({ ...modalState, visible: false })}
      >
        <PayRideModalContent
          target={modalState.target}
          onClose={() => {
            setModalState({ ...modalState, visible: false });
            dispatch({ type: "", id: "" });
          }}
          driverId={driver.id}
          rides={selectedRides.ids}
        />
      </CModal>
    </div>
  );
}
