import {
  CNav,
  CNavItem,
  CNavLink,
  CSpinner,
  CTabContent,
  CTabPane
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useLocation, useParams } from "react-router-dom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/styles.css";
import BoxWrapper from "../../components/BoxWrapper";
import DocViewer from "../../components/DocViewer";
import DriverDocument from "../../components/DriverDocument";
import PromptModal from "../../components/modals/PromptModal";
import {
  DriverProfileStatus,
  DriverProfileStatusDisplayValue
} from "../../helpers/enums";
import { useCookie } from "../../hooks/useCookie";
import { useToast } from "../../hooks/useToast";
import { general } from "../../locales/general";
import apiService from "../../service/apiService";
import AdminRoute from "../auth/RestrictedRoute";
import Balance from "./Balance";
import GeneralInfoForm from "./GeneralInfoForm";
import VehicleInfoForm from "./VehicleInfoForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faComputer,
  faLocationDot,
  faMobileScreen,
  faShield
} from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { formatDateString } from "../../helpers/general";
import DriverRides from "./DriverRides";

export default function Driver() {
  const params = useParams();
  const { token } = useCookie("vToken");
  const [docIndex, setDocIndex] = useState(-1);
  const [openDocViewer, setOpenDocViewer] = useState(false);
  const [docToView, setDocToView] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const { hash } = useLocation();
  const queryClient = useQueryClient();
  const { toastSuccess, toastError } = useToast();
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState("");

  const driverId = params?.driverId;

  const { data } = useQuery<Driver>(
    ["getDriver", driverId],
    () => {
      return apiService.MakeGetRequest(`users/driver/${driverId}`, token);
    },
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (hash) {
      const index = [
        "#generalInfo",
        "#vehicle",
        "#documents",
        "#solde",
        "#App",
        "#trajets"
      ].indexOf(hash);
      index >= 0 && setActiveTab(index);
    }
  }, [hash]);

  const driverDocs = [
    {
      src: data?.driverProfile?.pictureCarteCinRecto,
      noImage: !data?.driverProfile?.pictureCarteCinRecto,
      name: "pictureCarteCinRecto",
      label: "Carte Identité National Recto",
      docStatus: data?.driverProfile?.pictureCarteCinRectoStatus,
      expires: data?.driverProfile?.carteCinExpiration,
      showExpirationField: true,
      expirationField: "carteCinExpiration"
    },
    {
      src: data?.driverProfile?.pictureCarteCinVerso,
      noImage: !data?.driverProfile?.pictureCarteCinVerso,
      name: "pictureCarteCinVerso",
      label: "Carte Identité National Verso",
      docStatus: data?.driverProfile?.pictureCarteCinVersoStatus,
      expires: data?.driverProfile?.carteCinExpiration,
      showExpirationField: false
    },
    {
      src: data?.driverProfile?.picturePermisRecto,
      noImage: !data?.driverProfile?.picturePermisRecto,
      name: "picturePermisRecto",
      label: "Permis de conduire Recto",
      docStatus: data?.driverProfile?.picturePermisRectoStatus,
      expires: data?.driverProfile?.permisExpiration,
      showExpirationField: true,
      expirationField: "permisExpiration"
    },
    {
      src: data?.driverProfile?.picturePermisVerso,
      noImage: !data?.driverProfile?.picturePermisVerso,
      name: "picturePermisVerso",
      label: "Permis de conduire Verso",
      docStatus: data?.driverProfile?.picturePermisVersoStatus,
      expires: data?.driverProfile?.permisExpiration,
      showExpirationField: false
    },
    {
      src: data?.driverProfile?.picturePermisConfianceRecto,
      noImage: !data?.driverProfile?.picturePermisConfianceRecto,
      name: "picturePermisConfianceRecto",
      label: "Permis de Confiance Recto",
      docStatus: data?.driverProfile?.picturePermisConfianceRectoStatus,
      expires: data?.driverProfile?.permisConfianceExpiration,
      showExpirationField: true,
      expirationField: "permisConfianceExpiration"
    },
    {
      src: data?.driverProfile?.picturePermisConfianceVerso,
      noImage: !data?.driverProfile?.picturePermisConfianceVerso,
      name: "picturePermisConfianceVerso",
      label: "Permis de Confiance Verso",
      docStatus: data?.driverProfile?.picturePermisConfianceVersoStatus,
      expires: data?.driverProfile?.permisConfianceExpiration,
      showExpirationField: false
    },
    {
      src: data?.driverProfile?.pictureCarteConducteurProRecto,
      noImage: !data?.driverProfile?.pictureCarteConducteurProRecto,
      name: "pictureCarteConducteurProRecto",
      label: "Carte de Conducteur Professionnel Recto",
      docStatus: data?.driverProfile?.pictureCarteConducteurProRectoStatus,
      expires: data?.driverProfile?.carteConducteurExpiration,
      showExpirationField: true,
      expirationField: "carteConducteurExpiration"
    },
    {
      src: data?.driverProfile?.pictureCarteConducteurProVerso,
      noImage: !data?.driverProfile?.pictureCarteConducteurProVerso,
      name: "pictureCarteConducteurProVerso",
      label: "Carte de Conducteur Professionnel Verso",
      docStatus: data?.driverProfile?.pictureCarteConducteurProVersoStatus,
      expires: data?.driverProfile?.carteConducteurExpiration,
      showExpirationField: false
    }
  ];

  const vehicleDocs = [
    {
      src: data?.driverProfile?.vehicle?.frontPicture,
      noImage: !data?.driverProfile?.vehicle?.frontPicture,
      name: "frontPicture",
      label: "Photo d'un taxi vu de face",
      docStatus: data?.driverProfile?.vehicle?.frontPictureStatus,
      expires: "",
      showExpirationField: false
    },
    {
      src: data?.driverProfile?.vehicle?.sidePicture,
      noImage: !data?.driverProfile?.vehicle?.sidePicture,
      name: "sidePicture",
      label: "Photo d'un taxi de côté",
      docStatus: data?.driverProfile?.vehicle?.sidePictureStatus,
      expires: "",
      showExpirationField: false
    },
    {
      src: data?.driverProfile?.vehicle?.pictureCarteGriseRecto,
      noImage: !data?.driverProfile?.vehicle?.pictureCarteGriseRecto,
      name: "pictureCarteGriseRecto",
      label: "Carte Grise Recto",
      docStatus: data?.driverProfile?.vehicle?.pictureCarteGriseRectoStatus,
      expires: data?.driverProfile?.vehicle?.carteGriseExpiration,
      showExpirationField: true,
      expirationField: "carteGriseExpiration"
    },
    {
      src: data?.driverProfile?.vehicle?.pictureCarteGriseVerso,
      noImage: !data?.driverProfile?.vehicle?.pictureCarteGriseVerso,
      name: "pictureCarteGriseVerso",
      label: "Carte Grise Verso",
      docStatus: data?.driverProfile?.vehicle?.pictureCarteGriseVersoStatus,
      expires: data?.driverProfile?.vehicle?.carteGriseExpiration,
      showExpirationField: false
    }
  ];

  function getDriverStatusDisplayValue() {
    if (data?.driverProfile?.status === DriverProfileStatus.APPROVED) {
      return DriverProfileStatusDisplayValue.APPROVED;
    } else if (data?.driverProfile?.status === DriverProfileStatus.DECLINED) {
      return DriverProfileStatusDisplayValue.DECLINED;
    } else if (data?.driverProfile?.status === DriverProfileStatus.BLOCKED) {
      return DriverProfileStatusDisplayValue.BLOCKED;
    } else if (data?.driverProfile?.status === DriverProfileStatus.IN_REVIEW) {
      return DriverProfileStatusDisplayValue.IN_REVIEW;
    }
    return DriverProfileStatusDisplayValue.IN_REVIEW;
  }

  const AcceptDriver = useMutation((id: string) => {
    return apiService.MakePutRequest(`users/approve-profile/${id}`, {}, token);
  });

  const AcceptDriverButton = (id: string) => {
    AcceptDriver.mutate(id, {
      onError(error) {
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess("Chauffeur acceptée");
        queryClient.invalidateQueries(["getDriver"]);
      }
    });
  };

  const RefuseDriver = useMutation((id: string) => {
    return apiService.MakePutRequest(`users/decline-docs/${id}`, {}, token);
  });

  const RefuseDriverButton = (id: string) => {
    RefuseDriver.mutate(id, {
      onError(error) {
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess("Chauffeur refusée");
        queryClient.invalidateQueries(["getDriver"]);
      }
    });
  };

  interface docActionProps {
    action: string;
    id: string;
    docName: string;
    endPoint: string;
  }

  const handleDocAction = useMutation(
    ({ action, id, docName, endPoint }: docActionProps) => {
      return apiService.MakePutRequest(
        `${endPoint}/${action}-single-doc/${id}/${docName}`,
        {},
        token
      );
    }
  );

  const handleDocActionButton = (
    action: string,
    id: string,
    docName: string,
    endPoint: string
  ) => {
    handleDocAction.mutate(
      { action, id, docName, endPoint },
      {
        onError(error) {
          toastError(general.fr.message.operationFailed);
        },
        onSuccess() {
          toastSuccess(
            `Document ${action === "approve" ? "accepté" : "refusé"}`
          );
          setShowModal(false);
          queryClient.invalidateQueries(["getDriver"]);
        }
      }
    );
  };

  function handleAccpetRefuse() {
    if (action === "approveDoc") {
      handleDocActionButton(
        "approve",
        data?.id ?? "",
        driverDocs?.[docIndex]?.name,
        "users"
      );
    }

    if (action === "declineDoc") {
      handleDocActionButton(
        "decline",
        data?.id ?? "",
        driverDocs?.[docIndex]?.name,
        "users"
      );
    }

    if (action === "approveVehicleDoc") {
      handleDocActionButton(
        "approve",
        data?.driverProfile?.vehicle?.id ?? "",
        vehicleDocs?.[docIndex]?.name,
        "vehicles"
      );
    }

    if (action === "declineVehicleDoc") {
      handleDocActionButton(
        "decline",
        data?.driverProfile?.vehicle?.id ?? "",
        vehicleDocs?.[docIndex]?.name,
        "vehicles"
      );
    }

    if (action === "approveProfile") {
      AcceptDriverButton(data?.id ?? "");
    }

    if (action === "declineProfile") {
      RefuseDriverButton(data?.id ?? "");
    }
    setShowModal(false);
  }

  return (
    <AdminRoute>
      <section>
        <BoxWrapper>
          <div className="mx-4 d-flex justify-content-between flex-wrap gap-3 align-items-center pt-4 mb-3">
            <div className="d-flex flex-column justify-content-between align-items-start">
              <h5 className="fs-4 fw-bold mb-1">{data?.fullName}</h5>
              <p className="d-flex align-items-baseline gap-2">
                Status:
                <span
                  className={`text-${
                    data?.driverProfile?.status === DriverProfileStatus.APPROVED
                      ? "success"
                      : data?.driverProfile?.status ===
                          DriverProfileStatus.DECLINED ||
                        data?.driverProfile?.status ===
                          DriverProfileStatus.BLOCKED
                      ? "danger"
                      : "primary"
                  } fw-bold`}
                >
                  {getDriverStatusDisplayValue()}
                </span>
              </p>
            </div>
            <div className="d-flex align-items-baseline gap-2">
              <Link
                className="btn btn-success shadow-secondary rounded-pill p-2 mb-1 text-sm text-white"
                to={`/drivers/${data?.id}/upload-files`}
              >
                Upload documents
              </Link>
              {(data?.driverProfile?.status !== DriverProfileStatus.APPROVED ||
                data?.driverProfile === null) && (
                <button
                  className="btn btn-success shadow-secondary rounded-pill py-2 px-3 text-white"
                  onClick={() => {
                    setShowModal(true);
                    setAction("approveProfile");
                  }}
                  disabled={AcceptDriver.isLoading || driverDocs?.length === 0}
                >
                  {AcceptDriver.isLoading ? (
                    <CSpinner size="sm" />
                  ) : (
                    " Accepter"
                  )}
                </button>
              )}
              {(data?.driverProfile?.status !== DriverProfileStatus.DECLINED ||
                data?.driverProfile === null) && (
                <button
                  className="btn btn-danger shadow-primary rounded-pill py-2 px-3 text-white"
                  onClick={() => {
                    setShowModal(true);
                    setAction("declineProfile");
                  }}
                  disabled={RefuseDriver.isLoading || driverDocs?.length === 0}
                >
                  {RefuseDriver.isLoading ? <CSpinner size="sm" /> : " Refuser"}
                </button>
              )}
            </div>
          </div>

          <CNav variant="tabs" className="mb-2">
            <CNavItem
              href="#generalInfo"
              active={activeTab === 0}
              onClick={() => setActiveTab(0)}
            >
              <CNavLink
                className={`${activeTab === 0 ? "text-danger" : "text-black"}`}
              >
                Informations Générales
              </CNavLink>
            </CNavItem>

            <CNavItem
              href="#vehicle"
              active={activeTab === 1}
              onClick={() => setActiveTab(1)}
            >
              <CNavLink
                className={`${activeTab === 1 ? "text-danger" : "text-black"}`}
              >
                Informations de Véhicule
              </CNavLink>
            </CNavItem>

            <CNavItem
              href="#documents"
              active={activeTab === 2}
              onClick={() => setActiveTab(2)}
            >
              <CNavLink
                className={`${activeTab === 2 ? "text-danger" : "text-black"}`}
              >
                Documents
              </CNavLink>
            </CNavItem>

            <CNavItem
              href="#solde"
              active={activeTab === 3}
              onClick={() => setActiveTab(3)}
            >
              <CNavLink
                className={`${activeTab === 3 ? "text-danger" : "text-black"}`}
              >
                Solde
              </CNavLink>
            </CNavItem>

            <CNavItem
              href="#App"
              active={activeTab === 4}
              onClick={() => setActiveTab(4)}
            >
              <CNavLink
                className={`${activeTab === 4 ? "text-danger" : "text-black"}`}
              >
                Configuration de l'app
              </CNavLink>
            </CNavItem>
            <CNavItem
              href="#trajets"
              active={activeTab === 5}
              onClick={() => setActiveTab(5)}
            >
              <CNavLink
                className={`${activeTab === 5 ? "text-danger" : "text-black"}`}
              >
                Trajets
              </CNavLink>
            </CNavItem>
          </CNav>

          <CTabContent>
            {/* General Info */}
            <CTabPane visible={activeTab === 0}>
              <BoxWrapper>
                <GeneralInfoForm data={data} />
              </BoxWrapper>
            </CTabPane>

            {/* Vihicule */}
            <CTabPane visible={activeTab === 1}>
              <BoxWrapper>
                <div className="bg-white px-4 mx-auto">
                  <section>
                    <div className="row text-center">
                      <div className="col-md-12 row">
                        <div className="flex-wrap gap-3 align-items-center pt-4 mb-3">
                          <h5 className="fw-bold text-start">
                            Informations sur le véhicule
                          </h5>
                          <hr className="mt-1" />
                        </div>
                      </div>
                    </div>
                    <VehicleInfoForm
                      driverProfile={data?.driverProfile}
                      driverId={data?.id}
                    />
                  </section>
                  <div className="row text-center">
                    <div className="col-md-12 row">
                      <div className="flex-wrap gap-3 align-items-center pt-4 mb-3">
                        <h5 className="fw-bold text-start">
                          Documents de véhicule
                        </h5>
                        <hr className="mt-1" />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 row">
                    {vehicleDocs?.length > 0 ? (
                      vehicleDocs?.map((doc, index) => (
                        <div className="col-xxl-3 col-md-6 mb-4" key={index}>
                          <DriverDocument
                            doc={{
                              ...doc,
                              status: doc.docStatus,
                              image: doc.src
                            }}
                            onView={() => {
                              setOpenDocViewer(true);
                              setDocToView([doc]);
                              setDocIndex(index);
                            }}
                            onAccept={() => {
                              setShowModal(true);
                              setDocIndex(index);
                              setAction("approveVehicleDoc");
                            }}
                            onRefuse={() => {
                              setShowModal(true);
                              setDocIndex(index);
                              setAction("declineVehicleDoc");
                            }}
                            loading={handleDocAction.isLoading}
                          />
                        </div>
                      ))
                    ) : (
                      <p className="text-center py-2">Aucun document</p>
                    )}
                  </div>
                </div>
              </BoxWrapper>
            </CTabPane>

            {/* Documents */}
            <CTabPane visible={activeTab === 2}>
              <BoxWrapper>
                <div className="bg-white px-4 mx-auto">
                  {/* driver docs */}
                  <div className="row text-center">
                    <div className="col-md-12 row">
                      <div className="d-flex gap-3 align-items-center justify-content-between pt-4">
                        <h5 className="fw-bold text-start">
                          Documents de chauffeur
                        </h5>
                      </div>
                      <hr className="mt-1" />
                    </div>

                    <div className="col-md-12 row">
                      {driverDocs?.length > 0 ? (
                        driverDocs?.map((doc, index) => (
                          <div
                            className="col-lg-4 col-xxl-3 col-md-6 mb-4"
                            key={index}
                          >
                            <DriverDocument
                              doc={{
                                ...doc,
                                status: doc.docStatus,
                                image: doc.src
                              }}
                              onView={() => {
                                setOpenDocViewer(true);
                                setDocToView([doc]);
                                setDocIndex(index);
                              }}
                              onAccept={() => {
                                setShowModal(true);
                                setDocIndex(index);
                                setAction("approveDoc");
                              }}
                              onRefuse={() => {
                                setShowModal(true);
                                setDocIndex(index);
                                setAction("declineDoc");
                              }}
                              loading={handleDocAction.isLoading}
                            />
                          </div>
                        ))
                      ) : (
                        <p className="text-center py-2">Aucun document</p>
                      )}
                    </div>
                  </div>

                  {/* vehicle docs */}
                  <div className="row text-center">
                    <DocViewer
                      open={openDocViewer}
                      close={() => {
                        setOpenDocViewer(false);
                        setDocToView([]);
                        setDocIndex(-1);
                      }}
                      index={0}
                      docs={docToView}
                    />
                  </div>
                </div>
              </BoxWrapper>
            </CTabPane>
            {/*solde */}
            <CTabPane visible={activeTab === 3}>
              <BoxWrapper>
                <div className="bg-white px-4 mx-auto">
                  <section>
                    <div className="row text-center">
                      <div className="col-md-12 row">
                        <div className="flex-wrap gap-3 align-items-center pt-4 mb-3">
                          <h5 className="fw-bold text-start">Solde</h5>
                          <h5 className="fw-bold text-start">
                            {data?.balance?.toFixed(2)} DH
                          </h5>
                          <hr className="mt-1" />

                          <Balance data={data} />
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </BoxWrapper>
            </CTabPane>
            {/*APP */}
            <CTabPane visible={activeTab === 4}>
              <BoxWrapper>
                <div className="bg-white px-4">
                  <section>
                    <div className="row ">
                      <div className="col-md-12 row">
                        {data?.appSetup?.dates?.updatedAt !== undefined && (
                          <div className="flex-wrap gap-3 align-items-start pt-4 mb-3">
                            <h5 className="fw-bold text-start">
                              Informations sur l'application
                            </h5>

                            <hr className="mt-1" />

                            <div className="v_app-card-content">
                              {/* Last Update */}
                              <div className="v_app-section">
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className="v_app-icon"
                                />
                                <div className="v_app-content-wrapper">
                                  <h3 className="v_app-label">
                                    Dernière mise à jour des informations
                                  </h3>
                                  <p className="v_app-value">
                                    {/* {data?.appSetup?.dates?.updatedAt} */}
                                    {formatDateString(
                                      data?.appSetup?.dates?.updatedAt
                                    )}{" "}
                                    -{" "}
                                    {data?.appSetup?.dates?.updatedAt
                                      ? format(
                                          new Date(
                                            data?.appSetup?.dates?.updatedAt
                                          ),
                                          "HH:mm"
                                        )
                                      : ""}
                                  </p>
                                </div>
                              </div>

                              {/* Version */}
                              <div className="v_app-section">
                                <FontAwesomeIcon
                                  icon={faMobileScreen}
                                  className="v_app-icon"
                                />
                                <div className="v_app-content-wrapper">
                                  <h3 className="v_app-label">Version</h3>
                                  <p className="v_app-value">
                                    {data?.appSetup?.app?.version}
                                  </p>
                                </div>
                              </div>

                              {/* Permissions */}
                              <div className="v_app-section">
                                <FontAwesomeIcon
                                  icon={faShield}
                                  className="v_app-icon"
                                />
                                <div>
                                  <h3 className="v_app-label">Permissions</h3>
                                  <div className="v_app-permissions-grid">
                                    <p className="v_app-permission-label">
                                      Activity Recognition:
                                    </p>
                                    <p className="v_app-permission-value">
                                      {
                                        data?.appSetup?.permissions
                                          ?.activityRecognition
                                      }
                                    </p>

                                    <p className="v_app-permission-label">
                                      Battery Optimizations:
                                    </p>
                                    <p className="v_app-permission-value">
                                      {data?.appSetup?.permissions?.ignoreBatteryOptimizations.toString()}
                                    </p>

                                    <p className="v_app-permission-label">
                                      Location:
                                    </p>
                                    <p className="v_app-permission-value">
                                      {data?.appSetup?.permissions?.location}
                                    </p>

                                    <p className="v_app-permission-label">
                                      Notifications:
                                    </p>
                                    <p className="v_app-permission-value">
                                      {
                                        data?.appSetup?.permissions
                                          ?.notifications
                                      }
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/* Operating System */}
                              <div className="v_app-section">
                                <FontAwesomeIcon
                                  icon={faComputer}
                                  className="v_app-icon"
                                />
                                <div className="v_app-content-wrapper">
                                  <h3 className="v_app-label">
                                    Système d'exploitation
                                  </h3>
                                  <p className="v_app-value">
                                    {`${data?.appSetup?.platform?.os
                                      .charAt(0)
                                      .toUpperCase()}${data?.appSetup?.platform?.os.slice(
                                      1
                                    )}`}
                                  </p>
                                  <p className="v_app-version-info">
                                    Version: {data?.appSetup?.platform?.version}
                                  </p>
                                </div>
                              </div>

                              {/* Location Service */}
                              <div className="v_app-section">
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  className="v_app-icon"
                                />
                                <div className="v_app-content-wrapper">
                                  <h3 className="v_app-label">
                                    Service de localisation
                                  </h3>
                                  <p className="v_app-value">
                                    {data?.appSetup?.services
                                      ?.locationServiceEnabled
                                      ? "Activé"
                                      : "Désactivé"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {data?.appSetup?.dates?.updatedAt === undefined && (
                          <div className="v_app-label flex justify-center items-center">
                            <h2>Aucune information</h2>
                          </div>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </BoxWrapper>
            </CTabPane>
            <CTabPane visible={activeTab === 5}>
              <DriverRides driver={data} />
            </CTabPane>
          </CTabContent>
        </BoxWrapper>

        <PromptModal
          open={showModal}
          onAccept={() => handleAccpetRefuse()}
          onClose={() => setShowModal(false)}
          message={`Voulez-vous vraiment ${
            action.includes("approve") ? "accepter" : "refuser"
          } ce ${action.includes("Doc") ? "document" : "profile"}?`}
        />
      </section>
    </AdminRoute>
  );
}
