import { Controller, useFieldArray, useFormState } from "react-hook-form";
import { Input } from "../../../../components/inputs/Input";
import { CFormTextarea } from "@coreui/react";

interface CreateDiscountFormProps {
  control: any;
  isActive?: boolean;
  isPublic?: boolean;
}
export default function CreateDiscountForm({
  control,
  isActive = true,
  isPublic = true
}: CreateDiscountFormProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "discount"
  });

  const formState = useFormState({
    control,
    name: `discount`
  });

  const error = formState?.errors?.discount?.message as string;

  return (
    <section>
      <div className="create-discount-form">
        <Input
          name="name"
          control={control}
          type="text"
          label="Nom"
          placeholder="Nom"
        />
        <Input
          name="experationDate"
          control={control}
          type="date"
          label="Date d'expiration"
          placeholder="Date d'expiration"
        />
        <Input
          name="maxUsage"
          control={control}
          type="number"
          min={1}
          label="Utilisation maximale"
          placeholder="Utilisation maximale"
        />
        <Input
          name="maxPerUser"
          control={control}
          type="number"
          min={1}
          label="Utilisation maximale par utilisateur"
          placeholder="Utilisation maximale par utilisateur"
        />

        <Controller
          name="isActive"
          control={control}
          render={({ field }) => (
            <div className="d-flex align-items-center gap-1 mt-2">
              <input
                id="isActive"
                type="checkbox"
                {...field}
                defaultChecked={isActive}
              />
              <label htmlFor="isActive">Actif</label>
            </div>
          )}
        />
        <Controller
          name="isPublic"
          control={control}
          render={({ field }) => (
            <div className="d-flex align-items-center gap-1 mt-2">
              <input
                id="isPublic"
                type="checkbox"
                {...field}
                defaultChecked={isPublic}
              />
              <label htmlFor="isPublic">Public</label>
            </div>
          )}
        />
      </div>
      <div className="p-3 rounded-md border my-3">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <p className="fw-bold">Remise</p>
        </div>
        {fields.length === 0 && (
          <div className="mt-3">
            <p>Aucune remise</p>
          </div>
        )}
        {fields.map((field, index) => (
          <DiscountItem
            key={field.id}
            control={control}
            index={index}
            onRemove={() => remove(index)}
          />
        ))}
        {error && <p className="text-red">{error}</p>}
        <button
          type="button"
          className="btn btn-primary text-white"
          onClick={() => append({})}
        >
          Ajouter une remise
        </button>
      </div>

      <Controller
        name="comments"
        control={control}
        render={({ field }) => (
          <div>
            <label htmlFor="comments" className="d-block">
              Commentaires
            </label>
            <CFormTextarea
              id="comments"
              {...field}
              placeholder="Commentaires"
            />
          </div>
        )}
      />
    </section>
  );
}

interface DiscountItemProps {
  control: any;
  index: number;
  onRemove: () => void;
}
const DiscountItem = (props: DiscountItemProps) => {
  const { control, index, onRemove } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: `discount.${index}.value`
  });

  const formState: any = useFormState({
    control,
    name: `discount.${index}.value`
  });

  const error = formState?.errors?.discount?.[1]?.value?.message;

  return (
    <div className="mb-4 border-bottom pb-2">
      <Input
        name={`discount.${index}.registraction`}
        control={control}
        min={0}
        max={100}
        label="Registration"
        placeholder="Registration"
        type="number"
      />
      <div>
        <div className="d-flex align-item-center justify-content-between mt-3 gap-2">
          <p className="fw-bold">Coupon usage</p>
        </div>
        {fields.length === 0 && (
          <div className="mt-3">
            <p>Aucune usage</p>
          </div>
        )}

        {fields.map((field, idx) => (
          <div key={field.id} className="d-flex align-item-center mt-2 gap-2">
            <Input
              name={`discount.${index}.value.${idx}.usage`}
              control={control}
              type="text"
              label="Intervalle d'utilisation"
              placeholder="Intervalle d'utilisation"
            />
            <Input
              name={`discount.${index}.value.${idx}.value`}
              control={control}
              type="number"
              label="Valeur de la remise(%)"
              min={0}
            />
            <button
              className="btn text-danger"
              type="button"
              onClick={() => remove(idx)}
            >
              Supprimer
            </button>
          </div>
        ))}
      </div>
      {error && <p className="text-red">{error}</p>}
      <div className="d-flex align-items-center mt-3 gap-2">
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => append({})}
        >
          Ajouter une usage
        </button>
        <button className="btn btn-danger" type="button" onClick={onRemove}>
          Supprimer la remise
        </button>
      </div>
    </div>
  );
};
