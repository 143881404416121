import { CFormInput } from "@coreui/react";
import { useController } from "react-hook-form";

type InputProp = Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">;
type InputProps = {
  name: string;
  label?: string;
  control: any;
} & InputProp;

export function Input(props: InputProps) {
  const { defaultValue, name, control, label, ...rest } = props;
  const { field, fieldState } = useController({ name, control, defaultValue });

  return (
    <div>
      {label && <label className="d-block">{label}</label>}
      <CFormInput {...rest} {...field} />

      {fieldState.error && (
        <p className="text-red">{fieldState.error?.message}</p>
      )}
    </div>
  );
}
