export const general = {
  fr: {
    wait: "Veuillez attendre",
    noData: "Il n'y a aucune donnée à afficher",
    message: {
      operationFailed: "L'opération a échoué",
      bookingDeleted: "Réservation effacée avec succès",
      placeDeleted: "Établissement effacé avec succès",
      placeUpdated: "Établissement mise à jour avec succès",
      placeCreated: "Nouvel établissement créé",
      MenuAdded: "Menu ajouté avec succès",
      userCreated: "Nouvel utilisateur créé",
      userDeleted: "Utilisateur effacé avec succès",
      categoryCreated: "Nouvelle catégorie créée",
      categoryUpdated: "Catégorie mise à jour avec succès",
      categoryDeleted: "Catégorie effacée avec succès",
      partnerCreated: "Nouveau partenaire créé",
      partnerDeleted: "Partenaire effacé avec succès",
      partnerUpdated: "Partenaire mis à jour avec succès",
      cityCreated: "Nouvelle ville créée",
      cityUpdated: "Ville mise à jour avec succès",
      cityDeleted: "Ville effacée avec succès",
      offerCreated: "Nouvelle offre créée",
      offerUpdated: "Offre mise à jour avec succès",
      offerDeleted: "Offre effacée avec succès",
      eventCreated: "Nouvel évènement créé",
      eventUpdated: "Évènement mis à jour avec succès",
      eventDeleted: "Évènement effacé avec succès",
      eventTypeDeleted: "Évènement effacé avec succès",
      eventTypeCreated: "Nouvel Évènement créé",
      eventTypeUpdated: "Évènement mis à jour avec succès",
      packCreated: "Nouveau pack créé",
      packUpdated: "Pack mis à jour avec succès",
      packDeleted: "Pack effacé avec succès",
      packItemDeleted: "L'élément du pack effacé avec succès",
      UserAvatarUpdated: "Photo d'utilisateur mise à jour avec succès",
      logout: "Voulez-vous vraiment vous déconnecter ?",
      reviewDeleted: "Avis effacé avec succès",
      noDataToShow: "Pas de données à afficher",
      openingHrAdded: "Horaires d'ouverture ajoutés avec succès",
      serviceAdded: "Service ajouté avec succès",
      serviceDeleted: "Service effacé avec succès",
      serviceUpdated: "Service mis à jour avec succès",
      passwordFailed:
        "Le nouveau mot de passe ne correspond pas au mot de passe de confirmation",
      passwordSucces: "Fermeture de session réussie",
      emailDeleted: "Email effacé avec succès",
      inspectorDeleted: "Inspecteur effacé avec succès",
      inspectorCreated: "Nouvel inspecteur créé",
      pictureDeleted: "Photo effacée avec succès",
      pictureUploaded: "Photos téléchargées avec succès",
      profileUpdated: "Profil mis à jour avec succès",
      failedToCreateZone: "Échec de la création de la zone",
      failedToUpdateZone: "Échec de la mise à jour de la zone",
      zoneUpdate: "Zone actualisée avec succès",
      zoneDeleted: "Zone supprimée avec succès",
      FailedToUpdateAdmin:
        "Échec de la mise à jour de l'administrateur de l'établissement",
      companyInfoUpdated:
        "Les informations de la société ont été mises à jour avec succès",
      notificationCreated: "Notification créée avec succès",
      password: {
        success: "Mot de passe mis à jour avec succès",
        failed: "Échec de la mise à jour du mot de passe"
      },
      payoutRequestCreated: "Votre demande de retrait est prise en charge",
      withdrawalProcessed: "La demande est marquée comme traitée avec succès",
      specialEventCreated: "Nouvel évènement spécial créé",
      tourOfferCreated: "Nouvelle offre de tourisme créée",
      menuAdded: "Menu ajouté avec succès",
      variableDeleted: "Variable effacée avec succès",
      translationCreated: "La traduction a été créée avec succès",
      translationUpdated: "La traduction a été mise à jour avec succès",
      rideBonusCreated: "Bonus de trajet ajouté avec succès",
      rideStatusChanged: "Statut du trajet mise à jour avec succès"
    },
    delete: "Voulez-vous vraiment supprimer cet élément ?"
  }
};
