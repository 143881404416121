import { createBrowserRouter } from "react-router-dom";
import AppLayout from "./components/Layout";
import Bookings from "./pages/Bookings/Index";
import Categories from "./pages/Categories/Index";
import Cities from "./pages/Cities/Index";
import Events from "./pages/Events";
import NewEvent from "./pages/Events/NewEvent";
import Login from "./pages/Login";
import Offers from "./pages/Offers/Index";
import NewEventOffer from "./pages/Offers/NewOffer/NewEventOffer";
import NewSpecialOffer from "./pages/Offers/NewOffer/NewSpecialOffer";
import NewTourOffer from "./pages/Offers/NewOffer/NewTourOffer";
import UpdateOffer from "./pages/Offers/UpdateOffer";
import Partners from "./pages/Partners";
import Places from "./pages/Places";
import NewPlace from "./pages/Places/NewPlace";
import PlaceUpdateOffer from "./pages/Places/PlaceOffers/PlaceUpdateOffer";
import Home from "./pages/home";
import AllUsers from "./pages/users";

import { FormMode } from "./helpers/enums";
import Compte from "./pages/Compte";
import Change from "./pages/Compte/change";
import Drivers from "./pages/Drivers";
import Driver from "./pages/Drivers/Driver";
import ErrorPage from "./pages/ErrorPage";
import Event from "./pages/Events/Event";
import EventServices from "./pages/Events/EventServices";
import EventNewService from "./pages/Events/EventServices/EventNewService";
import OrganizeEvents from "./pages/Events/OrganizeEvents";
import EventReservation from "./pages/Events/reservations";
import Inspectors from "./pages/Inspectors";
import Invoices from "./pages/Invoices";
import Newsletter from "./pages/Newsletter";
import NotFound from "./pages/NotFound";
import Notifications from "./pages/Notifications";
import NewNotification from "./pages/Notifications/NewNotification";
import OrganizeOffers from "./pages/Offers/OrganizeOffers";
import Packs from "./pages/Packs";
import NewPack from "./pages/Packs/NewPack";
import Payouts from "./pages/Payouts";
import FloorPlanner from "./pages/Places/FloorPlanner";
import Place from "./pages/Places/Place";
import PlaceGallery from "./pages/Places/PlaceGallery";
import PlaceInspectors from "./pages/Places/PlaceInspectors";
import PlaceNewInspector from "./pages/Places/PlaceInspectors/PlaceNewInspector";
import PlaceOffers from "./pages/Places/PlaceOffers";
import PlaceNewOffer from "./pages/Places/PlaceOffers/PlaceNewOffer";
import PlaceReviews from "./pages/Places/PlaceReviews";
import PlaceServices from "./pages/Places/PlaceServices";
import PlaceNewService from "./pages/Places/PlaceServices/PlaceNewService";
import ServiceCategories from "./pages/ServicesCategories";
import Translations from "./pages/Translations";
import NewCategoryTranslation from "./pages/Translations/Categories/NewCategoryTranslation";
import NewEventTranslation from "./pages/Translations/Events/NewEventTranslation";
import NewPackTranslation from "./pages/Translations/Packs/NewPackTranslation";
import NewPlaceTranslation from "./pages/Translations/Places/NewPlaceTranslation";
import NewServiceCategoryTranslation from "./pages/Translations/ServiceCategories/NewServiceCategoryTranslation";
import NewServiceTranslation from "./pages/Translations/Services/NewServiceTranslation";
import NewSpecialTranslation from "./pages/Translations/Specials/NewSpecialTranslation";
import NewServiceSubCategoryTranslation from "./pages/Translations/serviceSubCategory/AddServiceSubCategory";
import Conversations from "./pages/Drivers/chats";
import Variables from "./pages/Variables";
import WhatsappMessages from "./pages/WhatsappMessages";
import Publicite from "./pages/advertisement";
import NewPublicite from "./pages/advertisement/NewAdvertisement";
import PromoCodes from "./pages/promoCode";
import AddNewPromoCode from "./pages/promoCode/AddNewPromoCode";
import UpdatePromoCode from "./pages/promoCode/UpdatePromoCode";
import PlaceUpdateInspector from "./pages/Places/PlaceInspectors/PlaceUpdateInspector";
import EventStatistics from "./pages/Events/EventStatistics";
import Riders from "./pages/taxi/Riders";
import RideConversations from "./pages/taxi/rides/conversations";
import TransactionsPage from "./pages/transactions";
import PendingDrivers from "./pages/Drivers/pending";
import AllTaxi from "./pages/taxi/AllTaxi";
import UploadDocument from "./pages/Drivers/UploadDocument";
import RideDetailsPage from "./pages/taxi/rides/ridesdetails";
import TaxiDiscounts from "./pages/taxi/discounts";
import CreateTaxiDiscount from "./pages/taxi/discounts/new";
import UpdateDiscount from "./pages/taxi/discounts/update";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />
  },
  {
    path: "/home",
    element: <Home />,
    errorElement: <ErrorPage />
  },
  {
    path: "*",
    element: <NotFound />
  },
  {
    element: <AppLayout />,
    index: false,
    children: [
      {
        path: "/users",
        element: <AllUsers />
      },
      {
        path: "/places",
        element: <Places />
      },
      {
        path: "/places/:placeId",
        element: <Place />
      },
      {
        path: "/places/:placeId/offers",
        element: <PlaceOffers />
      },
      {
        path: "/places/:placeId/services",
        element: <PlaceServices />
      },
      {
        path: "/places/:placeId/inspectors",
        element: <PlaceInspectors />
      },
      {
        path: "/places/:placeId/gallery",
        element: <PlaceGallery />
      },
      {
        path: "/places/:placeId/ratings",
        element: <PlaceReviews />
      },
      {
        path: "/newsletter",
        element: <Newsletter />
      },
      {
        path: "/inspectors",
        element: <Inspectors />
      },

      { path: "/offer/update/:id", element: <PlaceUpdateOffer /> },

      { path: "/places/:id/planner", element: <FloorPlanner /> },
      {
        path: "/service/update/:id",
        element: <PlaceNewService mode={FormMode.UPDATE} />
      },
      { path: "/places/:placeId/offer/new", element: <PlaceNewOffer /> },
      { path: "/places/:placeId/services/new", element: <PlaceNewService /> },
      {
        path: "/places/:placeId/inspectors/new",
        element: <PlaceNewInspector />
      },
      {
        path: "/places/:placeId/inspectors/:inspectorId/update",
        element: <PlaceUpdateInspector />
      },
      { path: "/places/:placeId/invoices", element: <Invoices /> },

      {
        path: "/events/:eventId",
        element: <Event />
      },
      {
        path: "/events/:eventId/services",
        element: <EventServices />
      },
      { path: "/events/:eventId/reservation", element: <EventReservation /> },
      {
        path: "/events/:eventId/services/new",
        element: <EventNewService mode={FormMode.CREATE} />
      },
      {
        path: "/events/:eventId/statistics",
        element: <EventStatistics />
      },
      {
        path: "/events/:eventId/services/:serviceId/update",
        element: <EventNewService mode={FormMode.UPDATE} />
      },

      {
        path: "/places/update/:id",
        element: <NewPlace mode={FormMode.UPDATE} />
      },
      { path: "/events", element: <Events /> },
      { path: "/events/new", element: <NewEvent mode={FormMode.CREATE} /> },
      {
        path: "/events/update/:id",
        element: <NewEvent mode={FormMode.UPDATE} />
      },
      { path: "/events/organize", element: <OrganizeEvents /> },

      { path: "/categories", element: <Categories /> },

      // * Service Categories Routes
      { path: "/service-categories", element: <ServiceCategories /> },

      { path: "/notifications", element: <Notifications /> },
      { path: "/notifications/new", element: <NewNotification /> },
      { path: "//whatsapp-messages", element: <WhatsappMessages /> },
      { path: "//drivers", element: <Drivers /> },
      { path: "/demandes", element: <PendingDrivers /> },
      { path: "//drivers/:driverId", element: <Driver /> },
      { path: "/drivers/:driverId/upload-files", element: <UploadDocument /> },
      { path: "/rides", element: <Riders /> },
      { path: "/rides/:rideId/conversations", element: <RideConversations /> },
      { path: "/rides/:rideId/ridedetails", element: <RideDetailsPage /> },

      { path: "/taxi", element: <AllTaxi /> },
      { path: "/taxi/discounts", element: <TaxiDiscounts /> },
      { path: "/taxi/discounts/new", element: <CreateTaxiDiscount /> },
      { path: "/taxi/discounts/update/:id", element: <UpdateDiscount /> },
      // { path: "//drivers/:driverId/conversations", element: <Conversations /> },
      { path: "/whatsapp-messages", element: <WhatsappMessages /> },
      // { path: "/drivers", element: <Drivers /> },
      // { path: "/drivers/:driverId", element: <Driver /> },
      { path: "/bookings", element: <Bookings /> },
      { path: "/offers", element: <Offers /> },
      { path: "/offers/organize", element: <OrganizeOffers /> },
      { path: "/offers/update/:id", element: <UpdateOffer /> },
      { path: "/offers/new/special-offer", element: <NewSpecialOffer /> },
      { path: "/offers/new/event-offer", element: <NewEventOffer /> },
      { path: "/offers/new/tour-offer", element: <NewTourOffer /> },
      {
        path: "/offers/update/special-offer/:id",
        element: <NewSpecialOffer mode={FormMode.UPDATE} />
      },
      {
        path: "/offers/update/event-offer/:id",
        element: <NewEventOffer mode={FormMode.UPDATE} />
      },
      {
        path: "/offers/update/tour-offer/:id",
        element: <NewTourOffer mode={FormMode.UPDATE} />
      },
      { path: "/packs", element: <Packs /> },
      { path: "/packs/new", element: <NewPack /> },
      {
        path: "/packs/update/:id",
        element: <NewPack mode={FormMode.UPDATE} />
      },
      { path: "/places/new", element: <NewPlace /> },
      { path: "/partners", element: <Partners /> },
      { path: "/cities", element: <Cities /> },
      { path: "/compte", element: <Compte /> },
      { path: "/change", element: <Change /> },
      { path: "/variables", element: <Variables /> },
      {
        path: "/payouts",
        element: <Payouts />
      },
      {
        path: "/translations",
        element: <Translations />
      },
      {
        path: "/promo-codes",
        element: <PromoCodes />
      },
      {
        path: "/promo-codes/new",
        element: <AddNewPromoCode />
      },
      {
        path: "/promo-codes/:id/edit",
        element: <UpdatePromoCode />
      },
      {
        path: "/promo-codes/:id/event",
        element: <PromoCodes view="events" />
      },
      {
        path: "/advertisement",
        element: <Publicite />
      },
      {
        path: "/advertisement/new",
        element: <NewPublicite />
      },
      {
        path: "/advertisement/update/:id",
        element: <NewPublicite mode={FormMode.UPDATE} />
      },
      {
        path: "/place-translations/update/:id",
        element: <NewPlaceTranslation mode={FormMode.UPDATE} />
      },
      { path: "/place-translations/new", element: <NewPlaceTranslation /> },
      {
        path: "/event-translations/update/:id",
        element: <NewEventTranslation mode={FormMode.UPDATE} />
      },
      { path: "/event-translations/new", element: <NewEventTranslation /> },
      {
        path: "/special-translations/update/:id",
        element: <NewSpecialTranslation mode={FormMode.UPDATE} />
      },
      { path: "/special-translations/new", element: <NewSpecialTranslation /> },
      {
        path: "/category-translations/update/:id",
        element: <NewCategoryTranslation mode={FormMode.UPDATE} />
      },
      {
        path: "/category-translations/new",
        element: <NewCategoryTranslation />
      },
      {
        path: "/service-translations/update/:id",
        element: <NewServiceTranslation mode={FormMode.UPDATE} />
      },
      { path: "/service-translations/new", element: <NewServiceTranslation /> },
      {
        path: "/pack-translations/update/:id",
        element: <NewPackTranslation mode={FormMode.UPDATE} />
      },
      { path: "/pack-translations/new", element: <NewPackTranslation /> },
      {
        path: "/service-category-translations/update/:id",
        element: <NewServiceCategoryTranslation mode={FormMode.UPDATE} />
      },
      {
        path: "/service-category-translations/new",
        element: <NewServiceCategoryTranslation />
      },
      {
        path: "/service-sub-category-translations/update/:id",
        element: <NewServiceSubCategoryTranslation mode={FormMode.UPDATE} />
      },
      {
        path: "/service-sub-category-translations/new",
        element: <NewServiceSubCategoryTranslation />
      },
      {
        path: "/transactions",
        element: <TransactionsPage />
      }
    ],
    errorElement: <ErrorPage />
  }
]);

export default router;
