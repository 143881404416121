import { any } from 'prop-types';
import React from 'react';

const MoroccanLicensePlate = ({
  vehicleNumber = "",
  width = 130,
  height = 25,
  fontSize = 14,
  borderWidth = 0.5,
  borderRadius = 5,
  textColor = '#212121' // equivalent to Colors.black87
}) => {
  const buildDivider = () => {
    return (
      <div 
        style={{
          height: `${height * 0.7}px`,
          width: `${borderWidth}px`,
          backgroundColor: textColor
        }}
      />
    );
  };

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        backgroundColor: '#F5F5F5', // equivalent to Colors.grey.shade100
        border: `${borderWidth}px solid #616161`, // equivalent to Colors.grey.shade700
        borderRadius: `${borderRadius}px`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          flex: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <span
          style={{
            fontSize: `${fontSize}px`,
            fontWeight: 500,
            color: textColor,
            fontFamily: '"Poppins", sans-serif',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {vehicleNumber.split('-')[0]}
        </span>
      </div>
      
      {buildDivider()}
      
      <div
        style={{
          flex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <span
          style={{
            fontSize: `${fontSize}px`,
            fontWeight: 500,
            color: textColor,
            fontFamily: '"Poppins", sans-serif'
          }}
        >
          {vehicleNumber.split('-')[1]}
        </span>
      </div>
      
      {buildDivider()}
      
      <div
        style={{
          flex: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <span
          style={{
            fontSize: `${fontSize}px`,
            fontWeight: 500,
            color: textColor,
            fontFamily: '"Poppins", sans-serif'
          }}
        >
          {vehicleNumber.split('-')[2]}
        </span>
      </div>
    </div>
  );
};

export default MoroccanLicensePlate;