import * as Yup from "yup";

export const createCouponSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  isActive: Yup.boolean().default(true),
  isPublic: Yup.boolean().default(true),
  experationDate: Yup.string().optional(),
  maxUsage: Yup.number()
    .integer()
    .optional()
    .typeError("Must be a number")
    .min(0, "Must be a positive number"),
  maxPerUser: Yup.number()
    .integer()
    .typeError("Must be a number")
    // .default(0)
    // .transform((value, originalValue) => {
    //   return originalValue === "" ? 0 : value;
    // })
    .min(0, "Must be a positive number"),
  comments: Yup.string().optional(),
  discount: Yup.array()
    .of(
      Yup.object().shape({
        registraction: Yup.number()
          .required("Registration is required")
          .min(0, "Must be a positive number"),
        value: Yup.array()
          .of(
            Yup.object().shape({
              usage: Yup.number()
                .required("Usage is required")
                .min(0, "Must be a positive number"),
              value: Yup.number()
                .required("Value is required")
                .min(0, "Must be a positive number")
            })
          )
          .min(1, "At least one value is required")
      })
    )
    .min(1, "At least one discount is required")
});
