import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useCookie } from "../../../../hooks/useCookie";
import { useToast } from "../../../../hooks/useToast";
import { general } from "../../../../locales/general";
import { createCouponSchema } from "../../../../rules/coupon-validation";
import apiService from "../../../../service/apiService";
import CreateDiscountForm from "../form/CreateDiscountForm";

type FormSchemaType = Yup.InferType<typeof createCouponSchema>;

export default function CreateTaxiDiscount() {
  const form = useForm<FormSchemaType>({
    resolver: yupResolver(createCouponSchema)
  });

  const { token } = useCookie("vToken");

  const createDiscountMutation = useMutation({
    mutationFn: (data: FormSchemaType) =>
      apiService.MakePostRequest("taxi-coupons", data, token),
    mutationKey: ["taxi-coupons"]
  });

  const { toastError, toastSuccess } = useToast();
  const navigate = useNavigate();

  const onSubmit = (data: FormSchemaType) => {
    createDiscountMutation.mutate(data, {
      onSuccess: () => {
        toastSuccess("Coupon créé avec succès");
        navigate("/taxi/discounts");
        form.reset();
      },
      onError: error => {
        toastError(general.fr.message.operationFailed);
      }
    });
  };

  return (
    <div className="bg-white p-3 rounded-md">
      <h1 className="text-lg fw-bold mb-4">Créer un Coupon</h1>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <CreateDiscountForm control={form.control} />
        <button
          className="btn btn-danger my-3 me-2"
          type="button"
          onClick={() => navigate("/taxi/discounts")}
          disabled={createDiscountMutation.isLoading}
        >
          Annuler
        </button>
        <button
          className="btn btn-primary my-3 text-white"
          type="submit"
          disabled={createDiscountMutation.isLoading}
        >
          {createDiscountMutation.isLoading
            ? "Création en cours..."
            : "Créer un Coupon"}
        </button>
      </form>
    </div>
  );
}
